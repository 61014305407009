import {Component, OnInit} from "@angular/core";
import {PageResponse} from "../user-list/common/paging";
import {ProfileListService} from "./profile-list.service";
import {PlaylistSearchService} from "../schedule/search-playlist/search-playlist.service";
import {DictionaryService} from "app/common/dictionary-service";
import {ChannelBean, CommonResultBean, FtpOptionsBean, ProfileBean, UserBean} from "../model/generated/dtos";
import {UserListService} from "../user-list/user-list.service";
import {FtpProfileService} from "./ftp-service";
import {CommonMessageService} from "../common/common-message-service";


@Component({
  selector: 'profile-list',
  templateUrl: './profile-list.component.html'
})
export class ProfileListComponent implements OnInit {

  isConnectedResponse: boolean = true;
  isValidFtpSection: boolean = false;
  connectionBean: CommonResultBean = {} as CommonResultBean;
  profileResponse: PageResponse<ProfileBean> = new PageResponse(0, 0, []);
  pageSize: number = 10;
  sortField: string = 'name';
  sortOrder: number = 1;
  formBean: ProfileBean = {} as ProfileBean;
  searchRequestBean: ProfileBean = {} as ProfileBean;
  deleteProfileBean: ProfileBean = {} as ProfileBean;
  currentName: string;
  inited: boolean;
  page: number = 0;
  ftpTypeOpen: boolean = false;
  disabledFTP: boolean = true;
  foundChannels: ChannelBean[];
  foundFilterChannels: ChannelBean[];
  foundFilterUsers: UserBean[];
  currentChannel: ChannelBean;
  searchCurrentChannel: ChannelBean;
  searchCurrentProfile: ProfileBean;
  searchedProfileQuery: string;
  foundProfiles: ProfileBean[];
  currentProfileName: string;
  selectedChannels: ChannelBean[] = [];
  isProfileEdit: boolean = false;
  isPasswordChangeRoller: boolean = false;
  newPassword: string;
  isPasswordChangeReq: boolean = false;
  isEditedProfileHasByFtp: boolean = false;

  //SLICE TEST
  initedSlice = false;
  hasNextPage = false;
  slice = 0;
  sliceSize = 10;
  testProfileList: ProfileBean[] = Array<ProfileBean>();


  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.profileName,
      propertyName: 'name',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.byFtp,
      propertyName: 'byFTP',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.byEmail,
      propertyName: 'byMail',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.byMaterialFTP,
      propertyName: 'byMaterialFTP',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.diffGeneration,
      propertyName: 'diffGeneration',
      selected: false,
      asc: true,
      clickable: true
    },
    {

      name: this.dictionaryService.dictionary.profileList.tableLabels.ftpHost,
      propertyName: 'ftpHost',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.ftpPort,
      propertyName: 'ftpPort',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.ftpProtocol,
      propertyName: 'ftpType',
      selected: false,
      asc: true,
      clickable: false
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.ftpUrl,
      propertyName: 'ftpUrl',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.ftpUsername,
      propertyName: 'ftpUsername',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.profileList.tableLabels.actions,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  ftpTypeMap = new Map([
    ['FTP', 'FTP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['FTPS', 'FTPS' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['SFTP', 'SFTP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['SFTP_OWN_KEY', this.dictionaryService.dictionary.channelList.dialog.sftpOwnKeyUsage],
    ['SFTP_PARTNER_KEY', this.dictionaryService.dictionary.channelList.dialog.sftpPartnerKeyUsage],
    ['ASCP', 'ASCP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['ASCP_OWN_KEY', this.dictionaryService.dictionary.channelList.dialog.ascpOwnKeyUsage],
    ['ASCP_PARTNER_KEY', this.dictionaryService.dictionary.channelList.dialog.ascpPartnerKeyUsage],
  ]);
  ftpTypeKeys = Array.from(this.ftpTypeMap.keys());

  isFtpOptionActive = false;
  ftpOptionsDict: FtpOptionsBean[] = [];

  constructor(private profileListService: ProfileListService,
              private searchService: PlaylistSearchService,
              protected dictionaryService: DictionaryService,
              protected userListService: UserListService,
              private messageService: CommonMessageService,
              private ftpProfileService: FtpProfileService) {
  }


  ngOnInit(): void {
    this.loadSliceProfiles();
    this.searchedProfileQuery = this.dictionaryService.dictionary.profileList.form.profileInputPlaceholder;

  }

  openPopup(profileBean?: ProfileBean): void {
    this.isPasswordChangeRoller = false;
    this.isPasswordChangeReq = false;
    this.disabledFTP = true;
    this.isFtpOptionActive = false;
    this.newPassword = null;
    if (profileBean) {
      // this.formBean = Object.assign({}, profileBean);
      // this.currentProfileName = this.formBean.name;
      this.getProfile(profileBean.id);
      this.isProfileEdit = true;
      this.isEditedProfileHasByFtp = profileBean.byFTP;
    } else {
      this.ftpOptionsDict.forEach(option => option.checked = false);
      this.isProfileEdit = false;
      this.isEditedProfileHasByFtp = false;
      this.formBean = {} as ProfileBean;
      this.formBean.channels = [];
      this.currentProfileName = this.dictionaryService.dictionary.profileList.dialog.header;
      this.formBean.tempPlaylistDays = 0;
    }
    var outerHtmlElement: any = document.getElementById('profile-form-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  getProfile(id: number) {
    this.profileListService.getProfile(id).subscribe(
      response => {
        this.formBean = Object.assign({}, response);
        if (this.formBean.channels == null) {
          this.formBean.channels = [];
        }
        this.currentProfileName = this.formBean.name;
        this.disabledFTP = !(this.formBean.byFTP != null && this.formBean.byFTP);
        this.ftpOptionsDict.forEach(option => {
          option.checked = this.formBean.ftpOptions != null
            && this.formBean.ftpOptions.find(ftpOption =>
              ftpOption.code === option.code) != null;
        });
      }
    );
  }


  fixedOpenPopup(dialogHtmlElement: any) {
    dialogHtmlElement.style.top = window.scrollY + 100 + 'px';
    dialogHtmlElement.setAttribute("open", "open");
    document.body.style.overflow = 'hidden';
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
    document.body.style.overflow = 'auto';
  }

  validateAndSaveForm() {
    this.trimFields();

    if (this.hasManyMistakes()) {
      if (this.isProfileEdit)
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.inputsRequiredEdit);
      else
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.inputsRequired);
      return false;
    }

    if (this.formBean.name == null || this.formBean.name.trim().length == 0) {
      this.messageService.error(this.dictionaryService.dictionary.profileList.messages.profileNameRequired);
      return false;
    }

    if (this.formBean.tempPlaylistDays == null) {
      this.messageService.error(this.dictionaryService.dictionary.profileList.messages.daysNumberRequired);
      return false;
    }

    if (this.formBean.tempPlaylistDays != null && (this.formBean.tempPlaylistDays < 0 || this.formBean.tempPlaylistDays > 62)) {
      this.messageService.error(this.dictionaryService.dictionary.profileList.messages.daysNumberValueRange);
      return false;
    }

    if (!this.validateUploadtype()) {
      this.messageService.error(this.dictionaryService.dictionary.profileList.messages.uploadTypeRequired);
      return false;
    }

    if (this.formBean.byFTP) {

      if (this.formBean.ftpType == null) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpProtocolRequired);
        return false;
      }

      if (this.formBean.ftpHost == null || this.formBean.ftpHost.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpHostRequired);
        return false;
      }

      if (this.formBean.ftpPort == null || this.formBean.ftpHost.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpPortRequired);
        return false;
      }

      if (this.formBean.ftpUsername == null || this.formBean.ftpUsername.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpUsernameRequired);
        return false;
      }

      if (!this.isKeysAuthentication(this.formBean.ftpType) && !this.isProfileEdit && (this.formBean.ftpPassword == null || this.formBean.ftpPassword.trim().length == 0)) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpPasswordRequired);
        return false;
      }

      if (!this.isKeysAuthentication(this.formBean.ftpType) && this.isProfileEdit && !this.isEditedProfileHasByFtp && (this.formBean.ftpPassword == null || this.formBean.ftpPassword.trim().length == 0)) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpPasswordRequired);
        return false;
      }

      if (this.formBean.ftpUrl == null || this.formBean.ftpUrl.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.profileList.messages.ftpUrlRequired);
        return false;
      }

    }
    this.saveProfile();
  }

  trimFields() {
    this.formBean.ftpHost = (this.formBean.ftpHost != null ? this.formBean.ftpHost.trim() : null);
    this.formBean.ftpUsername = (this.formBean.ftpUsername != null ? this.formBean.ftpUsername.trim() : null);
    this.formBean.ftpUrl = (this.formBean.ftpUrl != null ? this.formBean.ftpUrl.trim() : null);
  }

  saveProfile() {
    if (this.isProfileEdit) {
      if (this.isPasswordChangeReq) {
        this.formBean.ftpPassword = this.newPassword;
      } else if (this.formBean.ftpPassword != null && this.formBean.ftpPassword.length <= 0) {
        this.formBean.ftpPassword = null;
      }
    }
    this.saveProfileRequest();
  }

  saveProfileRequest() {
    this.isFtpOptionActive = false;
    this.formBean.ftpOptions = this.ftpOptionsDict.filter(option => option.checked);
    this.profileListService.saveProfile(this.formBean).subscribe(res => {
      var outerHtmlElement: any = document.getElementById('profile-form-dialog');
      this.fixedClosePopup(outerHtmlElement);
      this.messageService.success(this.dictionaryService.dictionary.profileList.messages.profileSaved);
      this.initedSlice = false;
      this.loadSliceProfiles();

    }, error => {
      console.error(error);
    });
  }

  detectPasswordChange() {
    if (this.newPassword != null && this.newPassword.length > 0) {
      this.isPasswordChangeReq = true;
    } else {
      this.isPasswordChangeReq = false;
      this.newPassword = null;
    }
  }

  validateUploadtype() {
    return this.formBean.byMail || this.formBean.byMaterialFTP || this.formBean.byFTP;
  }

  hasManyMistakes(): boolean {
    let mistakes = 0;
    (this.formBean.name == null || this.formBean.name.trim().length == 0) ? mistakes++ : null;
    (this.formBean.tempPlaylistDays == null) ? mistakes++ : null;
    (!this.validateUploadtype()) ? mistakes++ : null;
    if (this.formBean.byFTP) {
      (this.formBean.ftpType == null) ? mistakes++ : null;
      (this.formBean.ftpHost == null || this.formBean.ftpHost.trim().length == 0) ? mistakes++ : null;
      (this.formBean.ftpPort == null || this.formBean.ftpHost.trim().length == 0) ? mistakes++ : null;
      (this.formBean.ftpUsername == null || this.formBean.ftpUsername.trim().length == 0) ? mistakes++ : null;
      (!this.isProfileEdit && (this.formBean.ftpPassword == null || this.formBean.ftpPassword.trim().length == 0)) ? mistakes++ : null;
      (this.isProfileEdit && !this.isEditedProfileHasByFtp && (this.formBean.ftpPassword == null || this.formBean.ftpPassword.trim().length == 0)) ? mistakes++ : null;
      (this.formBean.ftpUrl == null || this.formBean.ftpUrl.trim().length == 0) ? mistakes++ : null;
    }

    return mistakes >= 2;
  }

  closeConfirmPopup(elementId: string): void {
    this.isFtpOptionActive = false;
    elementId = elementId || 'delete-profile-dialog';
    var outerHtmlElement: any = document.getElementById(elementId);
    this.fixedClosePopup(outerHtmlElement);
  }

  deleteProfile() {
    //TODO jbelczacka usuwanie profilu
    this.profileListService.deleteProfile(this.deleteProfileBean).subscribe({
      next: () => {
        this.messageService.success(this.dictionaryService.dictionary.profileList.messages.profileDeleted);
        this.closeConfirmPopup('delete-profile-dialog');
        this.deleteTableProfile(this.deleteProfileBean);

        this.initedSlice = false;
        this.hasNextPage = false;
        this.slice = 0;
        this.sliceSize = 10;
        this.loadSliceProfiles();
      },
      error: err => {
        console.error(err);
      }
    });
  }

  closePopup(): void {
    this.isFtpOptionActive = false;
    this.disabledFTP = true;
    let outerHtmlElement: any = document.getElementById('profile-form-dialog');
    this.fixedClosePopup(outerHtmlElement);
  }


  openConfirmDeletePopup(profile: ProfileBean): void {
    if (profile) {
      this.deleteProfileBean = Object.assign({}, profile);
    } else {
      this.deleteProfileBean = {} as ProfileBean;
    }
    var outerHtmlElement: any = document.getElementById('delete-profile-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  getSearchFilter() {
    let filter = Object.assign({}, this.searchRequestBean);
    if (this.searchCurrentChannel != null) {
      filter.channel = this.searchCurrentChannel;
      if (typeof this.searchCurrentChannel === "string") {
        let searchChannelBean = {} as ChannelBean;
        searchChannelBean.name = this.searchCurrentChannel;
        filter.channel = searchChannelBean;
      }
      if (filter.channel.name.trim().length == 0)
        filter.channel = null;

    }
    if (this.searchCurrentProfile != null) {
      if (typeof this.searchCurrentProfile === "string") {
        filter.name = this.searchCurrentProfile;
      } else {
        filter.name = this.searchCurrentProfile.name;
      }
      if (filter.name.trim().length == 0)
        filter.name = null;
    }
    return filter;
  }


  clearFilters(): any {
    this.searchRequestBean.name = '';
    this.searchCurrentChannel = null;
    this.searchCurrentProfile = null;
    this.searchedProfileQuery = this.dictionaryService.dictionary.profileList.form.profileInputPlaceholder;
    this.searchProfiles();
  }

  chooseFtpType(chosenFtpType: string): void {
    this.formBean.ftpType = chosenFtpType;
  }

  resetFtpType() {
    this.formBean.ftpType = null;
  }

  toggleFtpParameters() {
    //this.disabledFTP = this.selectedUploadType.indexOf('byFTP') == -1;
    this.disabledFTP = this.formBean.byFTP == false;
  }


  searchChannels(event) {
    let ids = this.formBean.channels.map(channel => channel.id).join(',');
    if (ids == null) {
      ids = '';
    }
    this.searchService.getChannelsByName(event.query, ids).subscribe(data => {
      this.foundChannels = data;
    });
  }

  searchFilterChannels(event) {
    this.searchService.getChannelsByName(event.query, '').subscribe(data => {
      this.foundFilterChannels = data;
    });

  }

  getSelectedChannel(event) {
    let channel = <ChannelBean>event;
    if (channel.id != -1) {
      this.addChannel(channel);
    } else {

    }
    this.currentChannel = null;
  }


  addChannel(channel: ChannelBean): void {
    this.formBean.channels.push(channel);
  }

  deleteInput(toDelete: ChannelBean) {
    this.formBean.channels.splice(this.formBean.channels.indexOf(toDelete), 1);
  }


  searchProfiles() {
    this.inited = false;
    this.initedSlice = false;
    // this.loadProfiles();
    this.loadSliceProfiles();

  }

  deleteTableProfile(toDelete: ProfileBean) {
    this.profileResponse.content.splice(this.profileResponse.content.indexOf(toDelete), 1);
  }

  loadSliceProfiles() {
    if (!this.initedSlice) {
      this.initedSlice = true;
      this.testProfileList = new Array<ProfileBean>();
      this.slice = 0;
    } else if (this.initedSlice) {
      this.slice += 1;
    }

    let filter = this.getSearchFilter();

    this.profileListService.getSlice(filter, this.slice, this.sliceSize, this.sortField, this.sortOrder).subscribe(
      response => {
        this.hasNextPage = response.hasNext;
        if (this.slice == 0)
          this.testProfileList = new Array<ProfileBean>();
        response.content.forEach(thread => {
          this.testProfileList.push(thread);
        });

      }
    );
    this.profileListService.getFtpOptionsDict().subscribe(
      {
        next: (result) => {
          this.ftpOptionsDict = result;
        }
      });
  }

  nextSlice(): void {
    this.loadSliceProfiles();
  }

  //SORT
  requestSorting(header: any): void {
    if (header.clickable) {
      this.sortField = header.propertyName;
      this.sortOrder = 0;
      if (header.asc) {
        this.sortOrder = 1;
      }
      this.searchProfiles();
    }
  }

  toggleArrow(index: number, header: any): void {
    if (this.headers[index].clickable) {
      if (this.headers[index].selected) {
        this.headers[index].asc = !this.headers[index].asc;
      } else {
        this.headers.forEach(header => header.selected = false);
        this.headers[index].selected = true;
      }
      this.requestSorting(header);
    }
  }

  //AUTOCOMPLETE PROFILE
  searchAutocompleteProfiles(event) {
    let name = event.query ? event.query : '';
    if (event.query == '') {
      this.currentProfileName = null;
      return null;
    }
    this.profileListService.getProfilesByName(name, "").subscribe(data => {
      this.foundProfiles = data;
    });
  }

  getSelectedProfile(event) {
    let profile = <ProfileBean>event;
    if (profile.id != -1) {
      this.searchCurrentProfile = profile;
    } else {
      this.currentProfileName = null;
    }

  }

  isNonEmpty(value: string) {
    return value != null && value != "" && value.trim().length > 0;
  }

  validateFtpSection() {

    if (typeof this.formBean.ftpPort === "string" && this.isNonEmpty(this.formBean.ftpPort)) {  // port is defined
      this.formBean.ftpPort = Number(this.formBean.ftpPort);
      if (!Number.isInteger(this.formBean.ftpPort) || (this.formBean.ftpPort < 0 || this.formBean.ftpPort > 65535))
        this.formBean.ftpPort = Number.NaN;
    } else if ((typeof this.formBean.ftpPort === "string" && !this.isNonEmpty(this.formBean.ftpPort)) || this.formBean.ftpPort == null)
      this.formBean.ftpPort = Number.NaN;


    this.isValidFtpSection = false;
    let anyFieldFilled = this.isNonEmpty(this.formBean.ftpType)
      || this.isNonEmpty(this.formBean.ftpHost)
      || (!Number.isNaN(this.formBean.ftpPort))
      || this.isNonEmpty(this.formBean.ftpUsername)
      || (!this.isProfileEdit && this.isNonEmpty(this.formBean.ftpPassword))
      || (this.isProfileEdit && this.isNonEmpty(this.newPassword))
      || this.isNonEmpty(this.formBean.ftpUrl);

    let allFieldsFilled = this.isNonEmpty(this.formBean.ftpType)
      && this.isNonEmpty(this.formBean.ftpHost)
      && (!Number.isNaN(this.formBean.ftpPort))
      && this.isNonEmpty(this.formBean.ftpUsername)
      && (this.isKeysAuthentication(this.formBean.ftpType)
        || this.isProfileEdit
        || (!this.isProfileEdit && this.isNonEmpty(this.formBean.ftpPassword))
      )
      && this.isNonEmpty(this.formBean.ftpUrl);

    if (anyFieldFilled) {
      this.isValidFtpSection = allFieldsFilled;
      if (!this.isValidFtpSection) {
        this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.ftpConfigurationError);
      } else
        this.isValidFtpSection = true;
    }
  }

  isKeysAuthentication(ftpType: string): boolean {
    return this.isNonEmpty(ftpType) && (ftpType === 'ASCP_OWN_KEY' || ftpType === 'ASCP_PARTNER_KEY'
      || ftpType === 'SFTP_OWN_KEY' || ftpType === 'SFTP_PARTNER_KEY');
  }

  isASCP(ftpType: string): boolean {
    return this.isNonEmpty(ftpType) && ftpType.startsWith('ASCP');
  }

  ftpConnection(): void {

    this.validateFtpSection();

    if (this.newPassword)
      this.formBean.ftpPassword = this.newPassword;

    if (this.isValidFtpSection) {
      this.isConnectedResponse = false;

      this.ftpProfileService.isConnected(this.formBean).subscribe(result => {
        this.connectionBean = result;
        switch (this.connectionBean.code) {
          case 0:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.correctConnection;
            break;
          case 1:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.correctConnectionWithBadUrl;
            break;
          case 2:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectHost;
            break;
          case 3:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectPort;
            break;
          case 4:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectUsernameOrPassword;
            break;
          case 5:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectHostOrPort;
            break;
          case 6:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.unknownProblem;
            break;
          case 7:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.timeout;
            break;
          case -100:
            this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.processModuleRestProblem;
            break;
          default:
            // use message from backend controller
            break;
        }
        this.isConnectedResponse = true;
      }, err => {
        this.isConnectedResponse = true;
        this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.ftpCheckFtpError);
      })
    } else
      this.formBean.ftpPort = null;
  }

  cleanConnectionBean(): void {
    this.connectionBean = {} as CommonResultBean;
  }

}

