export default {
  app: {
    playlistsLink: 'Playlists',
    statisticsLink: 'Statistics',
    controlPanelLink: 'Control Panel',
    usersLink: 'Users',
    profilesLink: 'Profiles',
    channelsGroupsLink: 'Channel groups',
    channelsLink: 'Channels',
    notificationsLink: 'Notifications',
    polish: 'Polish',
    english: 'English',
  },
  calendar: {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
      'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
  },
  playlists: {
    header: 'Playlist list',
    to: 'to',
    refresh: 'refresh',
    search: {
      choseChannelPlaceholder: 'Select channel group',
      selectAll: 'Select all',
      previousDayTitle: 'Previous day',
      nextDayTitle: 'Next day',
      searchButtonLabel: 'Search',
      clearButtonLabel: 'Clear',
      materialsLabel: 'Ingest',
      materialsTitle: 'New materials ready to emit',
      importButton: 'Import draft playlist',
      changeButton: 'Change material',
      restoreButtonLabel: 'Restore',
      group: 'GROUP'
    },
    grid: {
      channelHeader: 'Channel',
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      materialsTotal: 'Materials total',
      materialsMissing: 'Missing',
      materialsVirtual: 'Virtual',
      materialsReady: 'Ready',
      materialsDraft: 'Draft',
      materialsEmpty: 'EMPTY',
      materialsPending: 'Pending'
    }
  },
  playlistDetails: {
    header: 'Playlist details',
    transformationStatus: {
      new: 'Pending',
      update: 'Pending',
      lock: 'Pending',
      technical_error: 'Processing error',
      copying_error: 'Processing error',
      done: 'Sent',
      no_file: 'File missing'
    },
    errorDetails: 'Error details',
    transformationStatusLabel: 'FTP playlist status',
    errorDetailsNotAvailable: 'No error details.',
    errorRequest: 'Error while performing request',
    search: {
      channelNameLabel: 'Channel name',
      selectChannelPlaceholder: 'Select channel',
      dateLabel: 'Date',
      blockBeginningLabel: 'Block beginning',
      blockBeginningPlaceholder: 'FROM',
      blockEndingPlaceholder: 'TO',
      materialStatusLabel: 'Material status',
      partnerIdLabel: 'Partner ID',
      statuses: {
        ADMIN: {
          all: 'Any',
          sent_to_broadcaster: 'Ready',
          new: 'Pending',
          update: 'Pending',
          retry: 'Pending',
          retry_copying: 'Pending',
          file_not_exist: 'Material missing',
          prepare_emission: 'Pending',
          sending_to_broadcaster: 'Shipping',
          copying_error: 'Transfer error',
          processing_error: 'Processing error',
          processing_failed: 'Processing error',
          virtual: 'Virtual',
          material_null: 'Material not associated',
          technical_error: 'Processing error',
          lock: 'Pending',
          missing: 'Missing'
        },
        USER: {
          all: 'Any',
          sent_to_broadcaster: 'Ready',
          new: 'Pending',
          update: 'Pending',
          retry: 'Pending',
          retry_copying: 'Pending',
          file_not_exist: 'Pending',
          prepare_emission: 'Pending',
          processing: 'Pending',
          sending_to_broadcaster: 'Pending',
          copying_error: 'Pending',
          processing_error: 'Pending',
          processing_failed: 'Pending',
          technical_error: 'Pending',
          virtual: 'Virtual',
          material_null: 'Material not associated',
          lock: 'Pending',
          missing: 'Missing'
        }
      },
      checkboxMissingLabel: 'Missing',
      blockTypeLabel: 'Block type',
      blockTypes: {
        all: 'Any',
        OS: 'Social announcement',
        R: 'Advertising',
        B: 'Sponsorship'
      },
      orderTypes: {
        cennik: 'Rate card',
        pakiet: 'Package',
        sponsorski: 'Sponsorship'
      },
      filterButtonLabel: 'Search',
      clearButtonLabel: 'Clear filters',
      refreshButtonLabel: 'Refresh',
      resetTransformationButtonLabel: 'Retry',
    },
    table: {
      expandAllLabel: 'Expand all',
      retractAllLabel: 'Collapse all',
      compareVersionsLabel: 'Compare versions',
      versionLabel: 'Version',
      versionType: {
        'temporary': 'Draft version',
        'closed': 'Production version'
      },
      generatedDateLabel: 'Generated',
      downloadPlaylistButton: 'Download playlist',
      positionHeader: 'Position',
      blockBeginningHeader: 'Block beginning',
      blockSpotNameHeader: 'Block/spot name',
      partnerIdHeader: 'Partner ID',
      dlHeader: 'DL (mm:ss:fr)',
      fileTypeHeader: 'File type',
      blockOrderTypeHeader: 'Block/order type',
      beerHeader: 'Beer',
      materialStatusHeader: 'Material status',
      actionsHeader: 'Actions',
      noResults: 'No results has been found',
    },
    row: {
      expandLabel: 'Expand',
      retractLabel: 'Collapse',
    },
    messages: {
      refreshData: 'Data refresh requested',
      retryCopyingData: 'Data retry copying requested'
    }
  },
  playlistComparison: {
    header: 'Compare playlist',
    table: {
      version: 'Version',
      blockCount: 'Block count',
      spotCount: 'Spot count',
      generated: 'Generated',
      position: 'Position',
      blockBeginning: 'Block beginning',
      blockSpotName: 'Block/spot name',
      partnerIdHeader: 'Partner ID',
      dl: 'DL (min:ss:ff)',
      blockOrderType: 'Block/order type',
      beer: 'beer',
    },
    search: {
      channelName: 'Channel name',
      selectChannel: 'select channel',
      emissionDate: 'Emision date',
      ver1: 'Version 1',
      ver2: 'Version 2',
      selectVersion: 'select version',
      compareButton: 'Compare',
      downloadCompared: 'Download in Excel'
    },
    row: {
      differenceLabel: 'Spot difference',
      addedLabel: 'Added',
      deletedLabel: 'Deleted',
      modifiedLabel: 'Modified'
    }
  },
  channelGroupList: {
    header: 'Channel groups',
    dialog: {
      header: 'New channel group',
      nameInputLabel: 'Name',
      sufixListLabel: 'Sufixes list',
      sufixListDesc: 'List of suffixes apply to files created during processing of the material. In order to select files, which will be send to the FTP user should define list of sufixes. Files which names contain, in addition to material code, one of the predefined suffixes, will be copied to the broadcaster.',
      sufixListExample: ' Example suffix: .mpg',
      channelsHeader: 'CHANNELS',
      channelInputPlaceholder: 'CHOOSE CHANNEL',
      vantageHeader: 'CONFIGURUING VANTAGE PARAMETERS',
      groupNameWarning: 'Group name is required',
      save: 'Save'
    },
    confirmDialog: {
      header: 'Confirm',
      question: 'Confirm deleting of the channel group',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm'
    },
    form: {
      nameInputLabel: 'Name',
      nameInputPlaceholder: 'CHOOSE CHANNEL GROUP',
      channelInputLabel: 'Channel',
      channelInputPlaceholder: 'CHOOSE CHANEL',
      filterButton: 'Filter',
      clearFiltersButton: 'Clear filters',
      newChannelGroupButton: 'New channel group'
    },
    results: {
      editButton: 'Edit',
      deleteButton: 'Delete',
      noResults: 'No results has been found',
      showMore: 'Show more'
    },
    tableHeaders: {
      nameLabel: 'Name',
      workflowLabel: 'Workflow',
      workflowContextLabel: 'Workflow Context',
      suffixListLabel: 'Suffixes list',
      actionsLabel: 'Actions'
    },
    messages: {
      groupNameRequired: 'Field name is required',
      wokflowRequired: 'Field workflow is required',
      channelGroupSaved: 'Channel group has been saved',
      channelGroupDeleted: 'Channel group has been deleted',
      channelGroupNotDeleted: 'Can\'t delete channel group with undeleted channels',
      suffixDuplicate: 'This suffix is already in the list',
      suffixEmpty: 'Unable to add empty suffix to list'
    }
  },
  channelList: {
    header: 'Channels',
    dialog: {
      header: 'New channel',
      channelGroupInputLabel: 'Channel group',
      channelGroupInputPlaceholder: 'Choose channel group',
      nameInputLabel: 'Name',
      idInputLabel: 'ID number',
      channelLogoHeader: 'CHANNEL LOGO',
      addLogoLabel: 'Add logo',
      chooseFile: 'Choose',
      orLabel: 'or',
      dropFileLabel: 'Drop file here',
      chosenFileLabel: 'Chosen file',
      fileNameLabel: 'File name',
      ftpConfigurationHeader: 'CONNECTION CONFIGURATION',
      fileProtocolLabel: 'File protocol',
      fileProtocolNone: 'None',
      fileProtocolPlaceholder: 'Chose file protocol',
      ftpHost: 'Host name',
      ftpPort: 'Port number',
      ftpPortError: 'Invalid value in field! Number in de range 0-65535.',
      ftpUser: 'Username',
      diffGenOmit: 'Disable differences report generation',
      processOverwrite: 'Overwrite existing files on ftp',
      disabled: 'Channel disabled',
      autoRetry: 'Automatically retry importing material on error',
      ftpPassword: 'Password',
      ftpPasswordChange: 'Password change',
      ftpPasswordState: 'STATE',
      ftpOptions: 'FTP Options',
      ftpOptionsDescription: {
        FtpSslAllowOff: 'This option disables SSL for the FTP connection. SSL is a security protocol, so disabling it means that the connection will not be secured.',
        FtpSslProtectDataTrue: 'Despite SSL being turned off, this option enables data protection through SSL. This may be unnecessary when SSL is turned off.',
        SslCheckHostnameFalse: 'Disables SSL hostname validation. This can be useful when the hostname is not configured according to SSL certificate requirements.',
        SslVerifyCertificateNo: `Disables SSL certificate verification. This means the client won't check if the server's SSL certificate is valid, increasing the risk of a Man-in-the-Middle attack.`,
        SftpAutoConfirmYes: 'Automatically confirms SFTP operations without manual approval.',
        SftpConnectProgramSshRsa: 'Sets the ssh-rsa algorithm as preferred for SFTP connections. Secure and widely used.',
        SftpConnectProgramSshDss: 'Sets the ssh-dss (DSA) algorithm for SFTP connections. Deprecated and disabled in modern setups. Use only with very old systems.',
      },
      ftpPasswordStateChange: 'Password will be changed!',
      ftpPasswordStateNotChange: 'Password won\'t be changed',
      oldFtpPassword: 'Playlist old password',
      newFtpPassword: 'Playlist new password',
      ftpUrl: 'Server uri destination',
      limit: 'Maximum connection number',
      ftpDestination: 'Material destination(Vantage output)',
      ftpConfigurationError: 'FTP configuration is not finished! Clear or fill correctly all the fields',
      channelGroupError: 'Channel group and name are required',
      channelGroupOnlyError: 'Channel group is required!',
      channelNameOnlyError: 'Channel name is required!',
      saveButton: 'Save',
      connectButton: 'Check connection',
      ftpGetProcessUrlError: 'Error while getting process module url',
      ftpCheckFtpError: 'Error while checking ftp connection',
      downloadPublicKey: 'Download public key',
      userPassUsage: ' –  user + pass',
      ascpOwnKeyUsage: 'ASCP – own key',
      ascpPartnerKeyUsage: 'ASCP – partner key',
      sftpOwnKeyUsage: 'SFTP – own key',
      sftpPartnerKeyUsage: 'SFTP – partner key'
    },
    confirmDialog: {
      header: 'Confiramtion',
      question: 'Confirm deleting channel',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm'
    },
    form: {
      nameInputLabel: 'Name',
      nameInputPlaceholder: 'CHOOSE CHANNEL',
      channelGroupInputLabel: 'Channel group',
      channelGroupInputPlaceholder: 'CHOOSE CHANNEL GROUP',
      filterButton: 'Filter',
      clearFilterButton: 'Clear filters',
      newChannelButton: 'New channel',
      autoRetry: 'Automatic retry',
      turnOnAutoRetry: 'Turn on automatic retry',
      turnOffAutoRetry: 'Turn off automatic retry'
    },
    results: {
      editButton: 'Edit',
      deleteButton: 'Delete',
      noResults: 'No results has been found',
      showMore: 'Show more'
    },
    tableHeaders: {
      nameLabel: 'Name',
      channelGroupLabel: 'Channel group',
      typeLabel: 'Type',
      ftpHostNameLabel: 'FTP host',
      ftpPortLabel: 'Port number',
      ftpUserLabel: 'FTP username',
      ftpUrlLabel: 'FTP destination folder',
      ftpDestinationLabel: 'Material destination',
      actionsLabel: 'Actions'
    },
    messages: {
      channelSaved: 'Channel has been saved',
      logoAddingError: 'Error occurred during adding channel logo',
      channelDelete: 'Channel has been deleted!',
      logosSaved: 'Logos has been successfully added to corresponding channels',
      oldPassWrong: 'Old FTP password is wrong!',
      bothPassRequired: 'Old and new FTP password is required when FTP password is changing',
      autoRetriesTurnedOn: 'Automatic retries have been turned on',
      autoRetriesTurnedOff: 'Automatic retries have been turned off',
      autoRetriesSwitchError: 'An error occurred while trying to switch automatic retries',
    },
    ftpMessages: {
      correctConnection: 'Connection was successfully established.',
      correctConnectionWithBadUrl: 'Connection was refused: incorrect address url.',
      incorrectHost: 'Connection was refused: incorrect host name.',
      incorrectPort: 'Connection was refused: incorrect port number.',
      incorrectHostOrPort: 'Connection was refused: ftp service doesn\'t exist.',
      incorrectUsernameOrPassword: 'Connection was refused: incorrect username or password.',
      timeout: 'Connection was refused: timeout.',
      unknownProblem: 'Connection was refused: unknown error occurred.',
      processModuleRestProblem: 'Can\'t check ftp connection. Error while communicating with ftp handling module'
    }
  },
  notificationsConfiguration: {
    header: 'Notification configuration',
    confirmDialog: {
      header: 'Confirmation',
      questionFirstPart: 'Confirm deleting hour ',
      questionSecondPart: 'from your notification configuration',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm'
    },
    dialog: {
      newHourHeader: 'New time',
      newHourLabel: 'Time',
      newHourPlaceholder: 'Choose time',
      saveButton: 'Save'
    },
    standardSettings: {
      header: 'DEFAULT SETTINGS',
      subHeader: 'Default settings',
      firstOption: 'sending single mail to every channel from the list, ',
      secondOption: 'sending group mail everyday at 10:00',
      description: {
        firstPart: 'In section',
        secondPart: 'Channel notification list',
        thirdPart: 'There is possibility to choose which channels will receive single email notification.',
        fourthPart: 'In section',
        fifthPart: 'Receiving email group messages frequency configuration',
        sixthPart: 'There is possibility to define individual time of receiving the group email notifications about new playlist.',
        seventhPart: ' gives a possibility to define individual time of receiving the group reports about material error statuses.',
      }

    },
    list: {
      header: 'Channel email notifications list',
      noResults: 'No results has been found',
      frequencyConfigurationHeader: 'Receiving email group messages frequency configuration (daytime mode)',
      diffReportHeader: 'Differences report generation configuration',
      checkAll: 'Check all',
      uncheckAll: 'Uncheck all',
      addButton: 'Add',
      deleteButton: 'Delete',
    },
    tableHeaders: {
      channelNameLabel: 'Channel name',
      sendLabel: 'Send',
      hourLabel: 'Time',
      actionLabel: 'Action'
    },
    diffReportsConfigtableHeaders: {
      profileLabel: 'Profile',
      sendReportsLabel: 'Send reports'
    },
    messages: {
      changesSaved: 'Changes in notification configuration has been saved',
      channelConfigurationSavedFirstPart: 'Configuration for channel ',
      channelConfigurationSavedSecondPart: ' has been saved successfully',
      timeNotChosenError: 'Before proceeding select the time',
      timeDuplicateError: 'Chosen time has been already saved in notifications configuration'
    }
  },
  detailsPopup: {
    header: 'File details',
    previewTab: 'Preview',
    infoTab: 'File info',
    downloadButton: 'Download',
    spotTitle: 'Spot title',
    spotLength: 'Spot length',
    musicTitle: 'Music title',
    spotType: 'Spot type',
    copyrightDateBegin: 'Copyrights valid from',
    copyrightValidTo: 'Copyrights valid to',
    composer: 'Composer',
    lyricsAuthor: 'Lyrics author',
    screenplay: 'Screenplat',
    polishVersionAuthor: 'Polish version author',
    producer: 'Producer',
    director: 'Director',
    cinematographer: 'Cinematographer',
    format: 'Format'
  },
  statusPopup: {
    header: 'Status details'
  },
  profileList: {
    header: 'Profiles',
    dialog: {
      header: 'New Profile',
      nameInputLabel: 'Name',
      tempPlaylistDaysLabel: 'Playlist draft version possible to download for (number of days)',
      tempPlaylistDaysError: 'Invalid value in the field',
      channelsHeader: 'CHANNELS',
      channelInputPlaceholder: 'CHOOSE CHANNEL',
      diffReportGenerationLabel: 'Generating differences report',
      diffReportGenerationOption: 'Allow',
      sendReportViaLabel: 'Choose way of sending playlist files and differences report',
      sendReportViaOptions: {
        viaPlaylistFtp: 'Via playlist FTP',
        viaEmail: 'Via EMAIL',
        viaMaterialFtp: 'Via material FTP'
      },
      viaFtp: 'via FTP',
      viaEmail: 'via EMAIL',
      viaMaterialFtp: 'via material FTP',
      ftpParametersHeader: 'PLAYLIST FTP PARAMETERS',
      fileProtocolLabel: 'File protocol',
      fileProtocolPlaceholder: 'Choose file protocol',
      fileProtocolNone: 'None',
      ftpHost: 'Playlist FTP host name',
      ftpPort: 'FTP port',
      ftpPortError: 'Invalid value in the field!',
      ftpUser: 'Playlist FTP username',
      ftpPassword: 'Playlist FTP password',
      ftpPasswordChange: 'FTP password change',
      oldFtpPassword: 'Playlist FTP old password',
      newFtpPassword: 'Playlist FTP new password',
      ftpPasswordState: 'STATE',
      ftpPasswordStateChange: 'Password will be changed!',
      ftpPasswordStateNotChange: 'Password won\'t be changed',
      ftpUrl: 'Playlist FTP URL',
      saveButton: 'Save',
    },
    confirmDialog: {
      header: 'Confirmation',
      question: 'Confirm deleting the profile',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm',
    },
    form: {
      header: 'Profile',
      channelInputLabel: 'Channel',
      channelInputPlaceholder: 'CHOOSE CHANNEL',
      profileInputPlaceholder: 'NAME',
      filterButton: 'Filter',
      clearFilterButton: 'Clear fileters',
      newProfileButton: 'New profile'
    },
    table: {
      editButton: 'Edit',
      deleteButton: 'Delete',
      noResults: 'No results has been found',
      showMore: 'Show more'
    },
    tableLabels: {
      profileName: 'Profile \u000Aname',
      byFtp: 'Via \u000AFTP',
      byEmail: 'Via \u000AE\u2011MAIL',
      byMaterialFTP: 'Via \u000Amaterial \u000AFTP',
      diffGeneration: 'Access to \u000Adifferences \u000Areport',
      ftpHost: 'FTP \u000Ahost \u000Aname',
      ftpPort: 'Port \u000Anumber',
      ftpProtocol: 'File \u000Aprotocol',
      ftpUrl: 'FTP \u000Adestination folder',
      ftpUsername: 'FTP \u000Ausername',
      actions: 'Actions'
    },
    messages: {
      profileSaved: 'Profile has been saved',
      profileDeleted: 'Profile has been deleted',
      profileNameRequired: 'Profile name is required!',
      daysNumberRequired: 'Playlist draft version possible to download is required!',
      daysNumberValueRange: 'Playlist draft version possible to download input value only from 0 to 62!',
      uploadTypeRequired: 'Choosing way of sending playlist files is required!',
      ftpProtocolRequired: 'File protocol is required',
      ftpHostRequired: 'FTP host name is required!',
      ftpPortRequired: 'Port number is required!',
      ftpUsernameRequired: 'FTP username is required!',
      ftpPasswordRequired: 'FTP password is required!',
      ftpUrlRequired: 'FTP URL is required!',
      oldPassWrong: 'Old FTP password is wrong!',
      bothPassRequired: 'Old and new FTP password is required when FTP password is changing',
      inputsRequired: 'To add new profile you must correctly fill all required fields!',
      inputsRequiredEdit: 'To save changes you must correctly fill all required fields!',
    }
  },
  importPlaylist: {
    importDraftPlaylistHeader: 'Import draft playlist',
    // POLA
    draftPlaylistDateFromLabel: 'Import from',
    draftPlaylistDateToLabel: 'Import to',
    draftPlaylistImportDateLabel: 'Import date',
    draftPlaylistDatePlaceholder: 'Choose date',
    importDraftPlaylistChannelHeader: 'CHANNEL',
    channelsInputPlaceholder: 'Choose channel',
    importDraftPlaylistChannelGroupHeader: 'CHANNEL GROUP',
    channelGroupsInputPlaceholder: 'Choose channel group',
    importDraftPlaylistProfilesHeader: 'PROFILE',
    profileChannelsImport: `Import all user's channels`,
    // WALIDACJE
    importDateMissing: 'Import date missing',
    importChannelOrGroupMissing: 'Channel or Channel Group is mandatory',
    importChannelOrProfileChannel: 'Select channel or check the profile channels box',
    importDateOrderError: 'Import end date must be after import start date',
    importDateLimitError: 'Incorrect import range. Possible range is: ',
    // BŁĘDY
    errors: {
      requestError: 'Error while performing request',
      noPlayListError: 'No playlist for given parameters',
      savePlaylistError: 'Error while saving playlist to database',
      tooManyRequestsError: 'Too many requests, try again later',
      communicationError: 'Error with BNG communication',
      closedPlaylistExist: 'Production version of playlist on chosen date already exist  - unable to download temporary version.',
      zeroChannelsProfileError: 'The user profile does not have any channel',
      zeroChannelError: 'Channel not found',
      zeroChannelGroupError: 'No active channels found for the given channel group name',
    },
    // KOMUNIKATY KONCA
    importStart: 'Processing started',
    importFullSuccess: 'Import successfully ended.',
    importPartialSuccess: 'Import ended. Errors occured.',
    // OPIS STANU
    importPossibleDates: 'Possible date range',
    importPlaylistDone: 'Playlists import done',
    importInProgress: 'Import in progress',
    importSuccessfully: 'Import ended. Succesfully imported playlists',
    importErrors: 'Import errors',

    importErrorList: {
      channel: 'CHANNEL',
      playlistDate: 'PLAYLIST DATE',
      description: 'ERROR DESCRIPTION',
      zeroErrors: 'No import errors',
      beforeImport: 'Fill the form and start import'
    },

    importButton: 'Import',
    errorOccured: 'An error occured'
  },
  changeMaterialPopup: {
    header: 'Change material',
    possibleDates: 'Possible date range',
    oldMaterialHeader: 'Old material',
    newMaterialHeader: 'New material',
    oldMaterialInputPlaceholder: 'Choose material identifier',
    newMaterialInputPlaceholder: 'Choose material identifier',
    applyChangesButton: 'Apply change',
    wasTitle: 'Was',
    nowTitle: 'Now',
    // WALIDACJE
    dateMissing: 'Change material date missing',
    channelOrGroupMissing: 'Channel or Channel Group is mandatory',
    oldMaterialMissing: 'Field old material is required',
    newMaterialMissing: 'Field new material is required',
    dateOrderError: 'End date must be after start date',
    dateLimitError: 'Incorrect change material range. Possible range is: ',
    operationSuccess: 'Material changed successfully'
  },
  unauthorisedComponent: {
    header: 'Insufficient permissions',
    text: 'Contact administrator to get the access.'
  },
  authorisationWarning: {
    header: 'Insufficient permissions',
    text: 'Insufficient permissions to access site.'
  },
  userList: {
    tableLabels: {
      emailLabel: 'E-mail',
      createDateLabel: 'Creation date',
      firstNameLabel: 'Name',
      lastNameLabel: 'Last name',
      loginLabel: 'Login',
      phoneNumberLabel: 'Phone number',
      roleLabel: 'Role',
      profileLabel: 'Profile',
      statusLabel: 'Status',
      actionsLabel: 'Actions'
    },
    header: 'Users configuration',
    dialog: {
      header: 'New user',
      roleInputLabel: 'Role',
      roleInputPlaceholder: 'Choose role',
      roles: {
        roleAdmin: 'Administrator',
        roleManager: 'Manager',
        roleOperator: 'Operator',
        roleUser: 'User'
      },
      profilesHeader: 'PROFILE',
      profilesPlaceholder: 'CHOOSE PROFILE',
      emailInputLabel: 'E-mail',
      nameInputLabel: 'Name',
      surnameInputLabel: 'Surname',
      phoneInputLabel: 'Phone number',
      phoneInputError: 'Invalid data format in field phone number',
      saveButton: 'Save'
    },
    confirmDeleteDialog: {
      header: 'Confirmation',
      question: 'Confirm deleting user',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm',
    },
    confirmBlockDialog: {
      header: 'Confirmation',
      questionFirstPart: 'Confirm',
      questionSecondPartBlock: 'blocking',
      questionSecondPartUnblock: 'activating',
      questionThirdPart: 'user',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm'
    },
    form: {
      emailInputLabel: 'E-mail',
      loginInputLabel: 'Login',
      dateInputLabel: 'Creation date',
      dateFrom: 'from:',
      dateTo: 'to:',
      nameInputLabel: 'Name',
      surnameInputLabel: 'Surname',
      roleInputLabel: 'Role',
      allRoles: 'All',
      profileInputLabel: 'Profile',
      profileAnyPlaceholder: 'Any',
      filterButton: 'Filter',
      clearFilterButton: 'Clear filters',
      newUserButton: 'New user'
    },
    table: {
      active: 'Active',
      blocked: 'Blocked',
      editButton: 'Edit',
      blockButton: 'Block',
      unblockButton: 'Activate',
      deleteButton: 'Delete',
      showMore: 'Show more',
      noResults: 'No results has been found'
    },
    messages: {
      filterDateRangeError: 'Error in creation date filter! Beginning date must be before ending date',
      filterDateTodayError: 'Error in creation date filter! Beginning date must be before today\'s date',
      roleRequired: 'User must have defined role',
      emailRequired: 'E-mail field is required',
      emailInvalid: 'Wrong input format for e-mail field',
      nameRequired: 'Field name is required',
      lastNameRequired: 'Field last name is required',
      phoneNumberInvalid: 'In the filed phone number only digits are allowed',
      managerProfileError: 'Manager must have profile',
      userProfileError: 'User must have profile',
      userSaved: 'User has been saved',
      userDeleted: 'User has been deleted',
      userBlocked: 'User has been blocked',
      userUnblocked: 'User has been unblocked',
      inputsRequired: 'To add new user you must correctly fill all required fields!'
    }
  },
  userAgreement: {
    header: 'Terms of use',
    agreement1: {
      firstPart: 'I have read the ',
      link: 'terms and conditions',
      secondPart: 'and i accept them without restrictions.*'
    },
    agreement2: 'I hereby agree for processing my personal data by the TVN Media sp. z o.o. with its seat in Warsaw for the purpose of services provided by this website.*',
    agreement3: 'I hereby agree for processing my personal data by the TVN S.A. with its seat in Warsaw for the purpose of services provided by this website.*',
    agreement4: 'I hereby agree to receive business information by e-mail.*',
    buttonLabel: 'Save',
    messages: {
      agreementsAccepted: 'Acceptance of terms and conditions has been saved',
      agreementAcceptanceRequired: 'Accepting all of the terms and conditions is required'
    }
  },
  errors: {
    requestError: 'Error while performing request',
    channelGroupExist: 'Channel group  has already exist!',
    userMissing: 'User is not in the system',
    userNotLoggedIn: 'User is not signed in',
    authenticationRequired: 'Authentication required for this action',
    userEmailExist: 'User with this email already exist',
    badEmail: 'Invalid email',
    emailExist: 'Provided email already exist',
    unableToAddUser: 'Unable to add user',
    unableToDeleteProfile: 'Unable to delete profile if it is connected to the user',
    noSuchOrganization: 'Unexpected error occured! Organization with ID = $ not found!',
    noSuchCompany: 'Unexpected error occured! Company with ID = $ not found!',
    profileNotFound: 'Profile with ID: $ not found',
    channelNameDuplicate: 'Channel with this name already exist',
    channelIdDuplicate: 'Channel with this ID already exist',
    channelIdNull: 'Channel ID is mandatory!',
    channelIdBadValue: 'ID must be positive integer!',
    channelNameNull: 'Channel name is mandatory!',
    channelGroupNull: 'Channel group is mandatory!',
    channelLimitBadValue: 'Channel limit must be positive integer!',
    channelGroupNotFound: 'Channel group with ID: $ not found',
    channelGroupNameDuplicate: 'Channel group with name: $ already exist',
    channelNotFound: 'Channel with ID: $ not found',
    unableToDeleteChannelGroup: 'Unable to delete channel group! Channel group $ has assigned channels',
    profileNameDuplicate: 'Profile with name \'$\' already exist!',
    materialMissing: 'Material is missing',
    noStatusDetailsError: 'There is no error details for given element',
    uploadedFileTooLargeError: 'Choosed file is too large!',
    emptyPlaylistId: 'PlaylistId should not be null',
    playlistNotFound: 'Playlist with ID: $ not found',
    versionNotValid: 'You are working on the old playlist version. Reload page to see current version.',
    statusAlreadyChanged: 'Chosen playlist element have status that is different than his current version in system. Reload page to see current version.',
    noDivergencesReport: 'Report unavailable. All playlist should be closed to generate report.',
    noPermissionsSaveUserWithProfile: 'No permissions to create user account with this profile',
    noPermissionsDeleteUserWithProfile: 'No permission to delete user with this profile ',
    noPermissionsDisableUserWithProfile: 'No permission to disable user with this profile',
    noPermissionsEnableUserWithProfile: 'No permission to enable user with this profile',
    noPermissionRole: 'No permission to edit user with this role',
    noPermissionEdit: 'No permission to edit user',
    noRole: 'No user role. User role is required!',
    noProfileError: 'Profile is required for user and manager',
    noSuchUser: 'No such user with id: $',
    noNotifyConfigForUserAndChannel: 'No notify configuration for user and channel $',
    userValidationEmailMaxLength: 'E-mail is too long. Field max length: 255.',
    userValidationNameMaxLength: 'First name is too long. Field max length: 50.',
    userValidationLastNameMaxLength: 'Last name is too long. Field max length: 100.',
    userValidationNumberMaxLength: 'Phone number is too long. Field max length: 30.',
    materialNotFound: 'Not found material with given code.',
    moreThanOneMaterial: 'More than one material exists with given code.',
    channelOrChannelGroupNotFound: 'Channel not found',
    playlistElementsNotFound: 'Not found playlist elements with given filter',
    profileNameInvalid: 'Profile name is invalid',
    profileHostInvalid: 'Host is invalid',
    profileUrlInvalid: 'Url is invalid',
    profileUsernameInvalid: 'Username is invalid',
    profilePasswordInvalid: 'Password is invalid',
    profileTypeInvalid: 'Profile type is invalid',
    channelNameInvalid: 'Channel name is invalid',
    channelHostInvalid: 'Host name is invalid',
    channelUsernameInvalid: 'Username is invalid',
    channelPasswordInvalid: 'Password is invalid',
    channelPathInvalid: 'Logo filename is invalid',
    channelGroupNameInvalid: 'Group name is invalid',
    userFirstNameInvalid: 'First name is invalid',
    userLastNameInvalid: 'Last name is invalid',
    userLoginInvalid: 'Login is invalid',
    userPhoneNumberInvalid: 'Phon number is invalid',
    userEmailInvalid: 'Email is invalid',
    userRoleInvalid: 'Role is invalid',
    userProfileNameInvalid: 'Profile name is invalid',
    userNameInvalid: 'Name is invalid',
    materialCodeInvalid: 'Material code is invalid',
    materialNameInvalid: 'Material name is invalid',
    playlistNameInvalid: 'Playlist name is invalid',
    fileNameInvalid: 'File name is invalid',
    filterInvalid: 'Filter is invalid',
    importMaterialError: 'Error while importing material from external resource',
    limitOfConnectionExceeded: 'Limit of connection exceeded',
  },
  dictionaries: {
    roles: {
      ADMIN: 'Administrator',
      MANAGER: 'Manager',
      OPERATOR: 'Operator',
      USER: 'User',
      ALL: 'All'
    }
  },
  statistics: {
    header: 'Playlists elements statistics',
    playlistElementsHeader: 'PLAYLISTS ELEMENTS LIST',
    missingMaterialsHeader: 'MATERIALS THAT COULD NOT BE DOWNLOADED',
    retryProcessingResponse: 'Reprocessing was started for $ pairs [Channel - Material]',
    retryCopyingResponse: 'Retry copying was started for $ pairs [Channel - Material]',
    retryProcessingZeroError: 'There is no positions selected',
    retryProcessingZeroErrorResp: 'Reprocessing cancelled. Checked elements have outdated statuses. Data is refreshed. ',
    retryCopyingZeroErrorResp: 'Retry copying  cancelled. Checked elements have outdated statuses. Data is refreshed. ',
    retryProcessingError: 'Error occurred during trying to reprocess materials',
    getElementsError: 'Error while getting playlists elements list',
    showMore: 'Show more',
    noResults: 'No results has been found',
    expand: 'expand',
    shrink: 'shrink',
    refreshStatistics: 'Statistics refresh',
    statuses: {
      sent_to_broadcaster: 'Ready',
      new: 'Pending',
      update: 'Pending',
      retry: 'Pending',
      retry_copying: 'Pending',
      file_not_exist: 'Material missing',
      prepare_emission: 'Pending',
      sending_to_broadcaster: 'Shipping',
      copying_error: 'Transfer error',
      processing_error: 'Processing error',
      processing_failed: 'Processing error',
      virtual: 'Virtual',
      material_null: 'Material not associated',
      technical_error: 'Processing error',
      lock: 'Pending',
      missing: 'Missing'
    },
    playlistElements: {
      idTableHeader: 'Element\u00A0ID /Playlist\u00A0ID',
      actionTableHeader: 'Action',
      code: 'Code',
      channelGroup: 'Channel group',
      chooseChannelGroup: 'Choose channel group',
      channel: 'Channel',
      chooseChannel: 'Choose channel',
      playlistDate: 'Playlist date',
      fromDate: 'Date from',
      toDate: 'Date to',
      materialName: 'Material name',
      elementId: 'Element ID',
      materialStatus: 'Material status',
      materialStatusDetails: 'Material status details',
      statusDate: 'Status date',
      filter: 'Filter',
      cleanFilters: 'Clean filters',
      retry: 'Retry',
      retryCopy: 'Retry copying',
      checkAll: 'Check all',
      unCheckAll: 'Uncheck all',
      pageSize: 'Fetching rows quota',
      filterMatchingCount: {
        1: 'Matching',
        2: 'records'
      }
    },
    missingMaterials: {
      materialName: 'Material name',
      materialNameHeader: 'Material name [ID]',
      createDate: 'Create date',
      fromDate: 'Date from',
      toDate: 'Date to',
      retryCount: 'Retry count',
      filter: 'Filter',
      cleanFilters: 'Clean filters'
    }
  },
  controlPanel: {
    title: 'Control Panel',
    searchBox: {
      channelsGroupInput: {
        label: 'Grupa kanałowa',
        placeholder: 'Wybierz grupę kanałową'
      },
      channelInput: {
        label: 'Channel',
        placeholder: 'Choose channel'
      },
      playlistDates: {
        label: 'Playlist date',
        placeholderFrom: 'Date from',
        placeholderTo: 'Date to'
      },
      materialName: {
        label: 'Material name',
      },
      fileName: {
        label: 'File name',
      },
      sendDate: {
        label: 'Send date',
        placeholder: 'Choose date'
      },
      processingStatus: {
        label: 'Processing status',
        placeholder: 'Choose status'
      },
      showAll: {
        label: 'Show all',
      },
      filter: {
        label: 'Filter'
      },
      clearFilter: {
        label: 'Clear filters'
      },
      selectStatus: {
        material: 'Material',
        playlist: 'Playlist',
        any: 'ANY STATUS'
      }
    },
    fileTable: {
      headers: {
        database: 'Database',
        ftp: 'FTP',
        materialType: 'File type',
        materialName: 'Material name',
        fileNameDb: 'File name',
        processingStatus: 'Processing status',
        retryCounter: 'Retry counter',
        action: 'Action',
        fileNameFtp: 'File name',
        sendDateFtp: 'Date',
        fileSize: 'Size',
        fileHost: 'Host',
        filePort: 'Port',
        fileUser: 'User',
        fileDir: 'Directory',
      },
      data: {
        playlistPrefix: 'Playlist'
      },
      info: {
        chooseFilter: 'Choose channel group or channel and number of days',
        noResults: 'No results for chosen filters',
        noFileInfo: 'No file info',
        virtual: 'Material is virtual',
        fileNotFound: 'File not found',
        notApplicable: 'Not applicable'
      }
    },
    buttons: {
      checkAll: 'Check all',
      unCheckAll: 'Uncheck all',
      retry: 'Retry',
      retryCopy: 'Copy and overwrite',
    },
    processingStatus: {
      any: 'ANY STATUS',
      materialStatus: {
        sent_to_broadcaster: 'Ready',
        file_not_exist: 'Material missing',
        prepare_emission: 'Pending',
        new: 'Pending',
        update: 'Pending',
        retry: 'Pending',
        retry_copying: 'Pending',
        lock: 'Pending',
        sending_to_broadcaster: 'Shipping',
        copying_error: 'Transfer error',
        processing_error: 'Processing error',
        processing_failed: 'Processing error',
        technical_error: 'Processing error',
        virtual: 'Virtual'
      },
      materialSelect: {
        sent: 'Sent',
        not_exist: 'Material missing',
        prepare: 'Pending',
        sending: 'Shipping',
        copying_error: 'Transfer error',
        process_error: 'Processing error',
        virtual: 'Virtual'
      },
      playlistStatus: {
        done: 'Sent',
        no_file: 'File missing',
        new: 'Pending',
        update: 'Pending',
        lock: 'Pending',
        technical_error: 'Processing error',
        copying_error: 'Processing error',
      },
      playlistSelect: {
        done: 'Sent',
        no_file: 'File missing',
        sending: 'Pending',
        error: 'Processing error'
      }
    },
    errors: {
      getData: 'Getting history data error',
      getChannels: 'Get channels error',
      channelsGroupOrChannelNull: 'One of channels group or channel must be selected!',
      datesNull: 'Playlist dates are required!',
      datesOrder: 'Start date must be before end date!',
      datesRange: 'Date range error! Possible range: ',
      retryProcessing: 'Error occurred during trying to reprocess materials',
      retryProcessingZero: 'There is no positions selected',
      retryProcessingZeroResponse: 'Reprocessing cancelled. Checked elements have outdated statuses. Data is refreshed. ',
      retryProcessingResponse: 'Reprocessing was started for $ pairs [Channel - Material]',
      retryCopyingResponse: 'Retry copying was started for $ pairs [Channel - Material]',
      retryCopyingZeroResponse: 'Retry copying  cancelled. Checked elements have outdated statuses. Data is refreshed. ',

    },
    actions: {
      retry_ok: 'Retry copying started',
      retry_error: 'Error occurred during copy retrying!'
    },
    materialTypes: {
      EMISSION_FILE: 'Technical file',
      MATERIAL: 'Material',
      PLAYLIST: 'Playlist'
    }
  },
  footer: {
    policy_private: 'Policy Private',
  },
  user_panel: {
    account_menu: {
      other_applications: 'Other applications',
      change_password: 'Change Password',
      sign_out: 'Sign Out'
    }
  }
};
