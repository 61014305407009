import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PlaylistSearchService} from '../schedule/search-playlist/search-playlist.service';
import {AttachBoxComponent} from '../shared/attach-box/attach-box.component';
import {DictionaryService} from '../common/dictionary-service';
import {ChannelBean, ChannelsGroupBean, CommonResultBean, FtpOptionsBean} from '../model/generated/dtos';
import {Observable} from 'rxjs';
import {FtpChannelService} from './ftp-service';
import {CommonMessageService} from '../common/common-message-service';
import {environment} from '../../environments/environment';
import {LoaderService} from '../common/loader.service';
import {GlobalConfigService} from '../common/global-config-service';

@Component({
  selector: 'channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css'],
})
export class ChannelListComponent implements OnInit, AfterViewInit {


  @ViewChild('logoBox') logoBox: AttachBoxComponent;
  logoUploader: any;
  baseApiUrl: string = environment.BASE_API_URL;
  uploadLogoUrl: string = this.baseApiUrl + 'channels/upload/logo';
  uploadNewChannelWithLogoUrl: string = this.baseApiUrl + 'channels/uploadNew';
  uploadEditedChannelWithLogoUrl: string = this.baseApiUrl + 'channels/uploadEdited';

  withoutUrlIn = false;
  isConnectedResponse = true;
  connectionBean: CommonResultBean = {} as CommonResultBean;
  searchRequestBean: ChannelBean = {} as ChannelBean;
  formBean: ChannelBean = {} as ChannelBean;
  deleteChannelBean: ChannelBean = {} as ChannelBean;
  currentChannelName: string;
  logoOpen = false;
  searchChannelGroupsOpen = false;
  searchCurrentChannelGroup: ChannelsGroupBean;
  searchModalCurrentChannelGroup: ChannelsGroupBean;
  foundFilterChannelGroups: ChannelsGroupBean[];
  foundModalFilterChannelGroups: ChannelsGroupBean[];
  searchCurrentChannel: ChannelBean;
  foundFilterChannels: ChannelBean[];
  isFormValid = false;
  isFormSaved = false;
  isFormFTPSectionValid = false;
  channelDisabled: boolean;
  channelGroupDisabled: boolean;
  logoTimestamp: number;
  inited = false;
  hasNextPage = false;
  page = 0;
  pageSize = 10;
  sortField = 'name';
  sortOrder = 1;
  channelsList: ChannelBean[] = Array<ChannelBean>();
  isPasswordExisted = false;
  newPassword: string;
  isPasswordChangeReq = false;
  isPasswordChangeTabActive = false;
  isFtpOptionActive = false;
  isFtpSectionActive = false;
  isEditForm = true;
  formFtpPortError = false;
  isLogoActive = false;
  ftpTypeOpen = false;
  disabledFTP = false;

  isAutoRetryOn = false;

  // TABLE
  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.nameLabel,
      propertyName: 'name',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.channelGroupLabel,
      propertyName: 'channelGroup.name',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.typeLabel,
      propertyName: 'type',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.ftpHostNameLabel,
      propertyName: 'host',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.ftpPortLabel,
      propertyName: 'port',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.ftpUserLabel,
      propertyName: 'username',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.ftpUrlLabel,
      propertyName: 'url',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.ftpDestinationLabel,
      propertyName: 'urlIn',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelList.tableHeaders.actionsLabel,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  ftpTypeMap = new Map([
    ['FTP', 'FTP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['FTPS', 'FTPS' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['SFTP', 'SFTP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['SFTP_OWN_KEY', this.dictionaryService.dictionary.channelList.dialog.sftpOwnKeyUsage],
    ['SFTP_PARTNER_KEY', this.dictionaryService.dictionary.channelList.dialog.sftpPartnerKeyUsage],
    ['ASCP', 'ASCP' + this.dictionaryService.dictionary.channelList.dialog.userPassUsage],
    ['ASCP_OWN_KEY', this.dictionaryService.dictionary.channelList.dialog.ascpOwnKeyUsage],
    ['ASCP_PARTNER_KEY', this.dictionaryService.dictionary.channelList.dialog.ascpPartnerKeyUsage],
  ]);
  ftpTypeKeys = Array.from(this.ftpTypeMap.keys());

  ftpOptionsDict: FtpOptionsBean[] = [];

  constructor(private playlistSearchService: PlaylistSearchService,
              private messageService: CommonMessageService,
              public dictionaryService: DictionaryService,
              private ftpChannelService: FtpChannelService,
              private loaderService: LoaderService,
              private globalConfigService: GlobalConfigService) {

  }

  ngOnInit(): void {
    this.loadChannels();
    this.channelDisabled = false;
    this.channelGroupDisabled = false;
    this.globalConfigService.getIsAutoRetryOn()
      .subscribe((value) => this.isAutoRetryOn = value);
    this.playlistSearchService.getFtpOptionsDict().subscribe(
      {
        next: (result) => {
          this.ftpOptionsDict = result;
        }
      });
  }


  ngAfterViewInit(): void {
    this.logoUploader = this.logoBox.getUploader();
  }

  chooseGroup(group: ChannelsGroupBean) {
    this.formBean.channelGroup = group;
  }

  chooseSearchGroup(group: ChannelsGroupBean) {
    this.searchRequestBean.channelGroup = group;
  }

  // CRUD
  saveChannel() {
    this.isFormValid = true;
    this.trimFields();
    this.validateChannelForm();
    this.validateFTPSection();
    if (this.isFormValid && this.isFormFTPSectionValid) {
      this.handlePasswordField();
      this.saveChannelRequest();
    }
  }

  handlePasswordField() {
    if (this.isEditForm && this.isPasswordExisted) {
      this.formBean.password = (this.isPasswordChangeReq ? this.newPassword : null);
    }
    // else - hasło dla nowego ftp jest już w formBean.password
  }

  detectPasswordChange() {
    if (this.isPasswordExisted && this.newPassword != null && this.newPassword.length > 0) {
      this.isPasswordChangeReq = true;
    } else {
      this.isPasswordChangeReq = false;
      this.newPassword = null;
    }
  }

  trimFields() {
    this.formBean.host = (this.formBean.host != null ? this.formBean.host.trim() : null);
    this.formBean.username = (this.formBean.username != null ? this.formBean.username.trim() : null);
    this.formBean.url = (this.formBean.url != null ? this.formBean.url.trim() : null);
  }

  saveChannelRequest() {
    if (this.logoBox.getUploader().queue.length > 0) {
      this.saveChannelAndLogo(this.formBean);
    } else {
      this.saveOrUpdateChannel().subscribe({
        next: (_) => {
          const outerHtmlElement: any = document.getElementById('channel-form-dialog');
          this.fixedClosePopup(outerHtmlElement);
          this.searchChannels();
          this.messageService.success(this.dictionaryService.dictionary.channelList.messages.channelSaved);
        },
        error: error => {
          console.error(error);
        }
      });
    }
  }

  saveOrUpdateChannel(): Observable<any> {
    this.formBean.ftpOptions = this.ftpOptionsDict.filter(option => option.checked);
    if (this.isEditForm) {
      return this.playlistSearchService.saveEditedChannel(this.formBean);
    } else {
      return this.playlistSearchService.saveNewChannel(this.formBean);
    }
  }

  saveChannelAndLogo(channel: ChannelBean) {
    const uploader = this.logoUploader;
    const uploadUrl = (this.isEditForm ? this.uploadEditedChannelWithLogoUrl : this.uploadNewChannelWithLogoUrl);
    if (uploader.queue.length > 0) {
      uploader.setOptions({url: uploadUrl, removeAfterUpload: true});
      uploader.onBuildItemForm = (item, form) => {
        const channelVar = JSON.stringify(channel);

        form.append('channel', channelVar);
      };

      uploader.onCompleteItem = (item: any, response: any, status: any, _: any) => {
        if (status === '200' || status === 200) {
          const outerHtmlElement: any = document.getElementById('channel-form-dialog');
          this.fixedClosePopup(outerHtmlElement);
          this.searchChannels();
          this.messageService.success(this.dictionaryService.dictionary.channelList.messages.channelSaved);
        } else {
          const error = JSON.parse(response);
          if (this.dictionaryService.dictionary.errors[error.message] == null) {
            this.messageService.error(this.dictionaryService.dictionary.channelList.messages.logoAddingError);
          } else {
            this.messageService.error(this.dictionaryService.dictionary.errors[error.message]);
          }
        }
      };
      uploader.uploadItem(uploader.queue[uploader.queue.length - 1]);
    }

  }

  deleteChannel() {
    this.playlistSearchService.deleteChannel(this.deleteChannelBean).subscribe(
      {
        next: (_) => {
          this.closeConfirmPopup('delete-channel-dialog');
          this.searchChannels();
          this.messageService.success(this.dictionaryService.dictionary.channelList.messages.channelDelete);
        }, error: (error) => {
          console.error(error);
        }
      });
  }

  loadChannels() {
    if (!this.inited) {
      this.inited = true;
      this.channelsList = new Array<ChannelBean>();
      this.page = 0;
    } else if (this.inited) {
      this.page += 1;
    }

    this.buildFilter();

    this.playlistSearchService.getSliceChannels(this.searchRequestBean, this.page, this.pageSize, this.sortField, this.sortOrder).subscribe(
      response => {
        this.hasNextPage = response.hasNext;
        if (this.page === 0) {
          this.channelsList = new Array<ChannelBean>();
        }
        response.content.forEach(thread => {
          this.channelsList.push(thread);
        });

      }
    );
  }

  buildFilter() {

    this.searchRequestBean = {} as ChannelBean;
    if (this.searchCurrentChannelGroup != null) {
      if (typeof this.searchCurrentChannelGroup === 'object') {
        this.searchRequestBean.channelGroup = this.searchCurrentChannelGroup;
      } else {
        if (this.searchCurrentChannelGroup !== '') {
          this.searchRequestBean.channelGroup = {} as ChannelsGroupBean;
          this.searchRequestBean.channelGroup.name = this.searchCurrentChannelGroup;
        }
      }
    }
    if (this.searchCurrentChannel != null) {
      if (typeof this.searchCurrentChannel === 'object') {
        this.searchRequestBean = this.searchCurrentChannel;
      } else {
        if (this.searchCurrentChannel !== '') {
          this.searchRequestBean.name = this.searchCurrentChannel;
        }
      }
    }
  }

  nextSlice(): void {
    this.loadChannels();
  }

  clearSearchFilter() {
    this.searchRequestBean = {} as ChannelBean;
    this.searchCurrentChannel = null;
    this.searchCurrentChannelGroup = null;
    this.inited = false;
    this.loadChannels();
    this.autocompleteModelChange();
  }

  searchChannels() {
    this.inited = false;
    this.loadChannels();
  }

  scaleSections() {
    this.isPasswordChangeReq = false;
    this.isPasswordChangeTabActive = false;
    this.isFtpOptionActive = false;
    this.isFtpSectionActive = false;
    this.formFtpPortError = false;
    this.isLogoActive = false;
  }

  // POPUPS
  openPopup(channel?: ChannelBean): void {
    this.newPassword = null;
    this.scaleSections();
    if (channel) {
      this.ftpOptionsDict.forEach(option => {
        option.checked = channel.ftpOptions != null
          && channel.ftpOptions.find(ftpOption =>
            ftpOption.code === option.code) != null;
      });
      this.isEditForm = true;
      (this.isNonEmpty(channel.password)) ? this.isPasswordExisted = true : this.isPasswordExisted = false;
      this.formBean = Object.assign({}, channel);
      this.currentChannelName = this.formBean.name;
      this.searchModalCurrentChannelGroup = this.formBean.channelGroup;
      this.formBean.type = this.isNonEmpty(this.formBean.type) ? this.formBean.type.toUpperCase() : this.formBean.type;
    } else {
      this.ftpOptionsDict.forEach(option => option.checked = false);
      this.searchModalCurrentChannelGroup = null;
      this.isEditForm = false;
      this.isPasswordExisted = false;
      this.isFormValid = false;
      this.isFormFTPSectionValid = false;
      this.formBean = {} as ChannelBean;
      this.currentChannelName = this.dictionaryService.dictionary.channelList.dialog.header;
    }
    const outerHtmlElement: any = document.getElementById('channel-form-dialog');

    this.fixedOpenPopup(outerHtmlElement);
  }

  openConfirmDeletePopup(channel: ChannelBean): void {
    if (channel) {
      this.deleteChannelBean = Object.assign({}, channel);
    } else {
      this.deleteChannelBean = {} as ChannelBean;
    }
    const outerHtmlElement: any = document.getElementById('delete-channel-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  closePopup(): void {
    this.scaleSections();
    const outerHtmlElement: any = document.getElementById('channel-form-dialog');
    this.searchModalCurrentChannelGroup = null;
    if (this.logoUploader.queue.length > 0) {
      this.logoUploader.clearQueue();
    }
    this.fixedClosePopup(outerHtmlElement);
  }

  fixedOpenPopup(dialogHtmlElement: any) {
    this.isFormSaved = false;
    dialogHtmlElement.style.top = window.scrollY + 100 + 'px';
    dialogHtmlElement.setAttribute('open', 'open');
    document.body.style.overflow = 'hidden';
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute('open');
    document.body.style.overflow = 'auto';

  }

  closeConfirmPopup(elementId: string): void {
    elementId = elementId || 'delete-channel-dialog';
    const outerHtmlElement: any = document.getElementById(elementId);
    this.fixedClosePopup(outerHtmlElement);
  }

  chooseFtpType(chosenFtpType: string): void {
    this.formBean.type = chosenFtpType;
  }

  resetFtpType() {
    this.formBean.type = null;
  }

  // SORT
  requestSorting(header: any): void {
    if (header.clickable) {
      this.sortField = header.propertyName;
      this.sortOrder = 0;
      if (header.asc) {
        this.sortOrder = 1;
      }
      this.searchChannels();
    }
  }

  toggleArrow(index: number, header: any): void {
    if (this.headers[index].clickable) {
      if (this.headers[index].selected) {
        this.headers[index].asc = !this.headers[index].asc;
      } else {
        this.headers.forEach(headersHeader => headersHeader.selected = false);
        this.headers[index].selected = true;
      }
      this.requestSorting(header);
    }
  }

  deleteLogo() {
    this.logoBox.clearFiles();
  }

  deleteExistedLogo() {
    this.formBean.logoRelativePath = null;
  }

  attachLogosToAllChannels() {
    this.playlistSearchService.attachLogos().subscribe(
      _ => {
        this.messageService.success(this.dictionaryService.dictionary.channelList.messages.logosSaved);
      }
    );
  }

  // AUTOCOMPLETE
  searchFilterChannelGroups(event) {
    this.playlistSearchService.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.foundFilterChannelGroups = data;
    });
  }

  searchModalFilterChannelGroups(event) {
    this.playlistSearchService.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.foundModalFilterChannelGroups = data;
    });
  }

  searchFilterChannels(event) {
    this.playlistSearchService.getChannelsByNameWithDisabled(event.query).subscribe(data => {
      this.foundFilterChannels = data;
    });
  }

  // VALIDATION
  validateChannelForm() {
    if (this.formBean.channelGroup == null && (this.formBean.name == null || this.formBean.name.trim().length === 0)) {
      this.isFormValid = false;
      this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.channelGroupError);
    } else if (this.formBean.channelGroup == null) {
      this.isFormValid = false;
      this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.channelGroupOnlyError);
    } else if (this.formBean.name == null || this.formBean.name.trim().length === 0) {
      this.isFormValid = false;
      this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.channelNameOnlyError);
    }
  }

  isNonEmpty(value: string) {
    return value != null && value !== '' && value.trim().length > 0;
  }

  validateFTPSection() {
    let portExist: boolean;
    let portValid: boolean;
    const portValue = this.formBean.port;

    if (this.newPassword) {
      this.formBean.password = this.newPassword;
    }

    if (portValue != null && ((typeof portValue === 'number') || (typeof portValue === 'string' && this.isNonEmpty(portValue)))) {
      portExist = true;
      const tmpVal = Number(portValue);
      portValid = (Number.isInteger(tmpVal) && tmpVal >= 0 && tmpVal < 65535);
      if (portValid) {
        this.formBean.port = tmpVal;
        this.formFtpPortError = false;
      } else {
        this.formFtpPortError = true;
      }
    } else {
      portExist = false;
      portValid = false;
      this.formFtpPortError = false;
    }


    const anyFieldFilled =
      this.isNonEmpty(this.formBean.type)
      || this.isNonEmpty(this.formBean.host)
      || (!this.isPasswordExisted && this.isNonEmpty(this.formBean.password))
      || (portExist)
      || this.isNonEmpty(this.formBean.url)
      || this.isNonEmpty(this.formBean.username)
      || (this.withoutUrlIn || this.isNonEmpty(this.formBean.urlIn));

    const allFieldsFilled =
      this.isNonEmpty(this.formBean.type)
      && this.isNonEmpty(this.formBean.host)
      && (this.isKeysAuthentication(this.formBean.type) || this.isPasswordExisted
        || (!this.isPasswordExisted && this.isNonEmpty(this.formBean.password)))
      && (portExist && portValid)
      && this.isNonEmpty(this.formBean.url)
      && this.isNonEmpty(this.formBean.username)
      && (this.withoutUrlIn || this.isNonEmpty(this.formBean.urlIn));

    if (anyFieldFilled) {
      this.isFormFTPSectionValid = allFieldsFilled;
      if (!this.isFormFTPSectionValid) {
        this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.ftpConfigurationError);
      }
    } else {
      this.isFormFTPSectionValid = true;
    }
  }

  autocompleteModelChange(): void {
    this.channelGroupDisabled = this.getDisabledValue(this.searchCurrentChannel);
    this.channelDisabled = this.getDisabledValue(this.searchCurrentChannelGroup);
  }

  private getDisabledValue(object): boolean {
    if (object != null) {
      if (typeof object === 'object') {
        return object.name !== '';
      } else {
        return object !== '';
      }
    } else {
      return false;
    }
  }

  onLogoOpen(_: any) {
    this.logoTimestamp = new Date().getTime();
  }

  onLogoClose(_: any) {
    this.logoTimestamp = new Date().getTime();
  }

  ftpConnection(): void {

    this.withoutUrlIn = true;
    this.validateFTPSection();
    this.withoutUrlIn = false;

    if (this.isFormFTPSectionValid) {
      this.isConnectedResponse = false;
      this.ftpChannelService.isConnected(this.formBean, this.ftpOptionsDict).subscribe({
        next: (result) => {
          this.connectionBean = result;
          switch (this.connectionBean.code) {
            case 0:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.correctConnection;
              break;
            case 1:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.correctConnectionWithBadUrl;
              break;
            case 2:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectHost;
              break;
            case 3:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectPort;
              break;
            case 4:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectUsernameOrPassword;
              break;
            case 5:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.incorrectHostOrPort;
              break;
            case 6:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.unknownProblem;
              break;
            case 7:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.timeout;
              break;
            case -100:
              this.connectionBean.message = this.dictionaryService.dictionary.channelList.ftpMessages.processModuleRestProblem;
              break;
            default:
              // use message from backend controller
              break;
          }
          this.isConnectedResponse = true;
        },
        error: _ => {
          this.isConnectedResponse = true;
          this.messageService.error(this.dictionaryService.dictionary.channelList.dialog.ftpCheckFtpError);
        }
      });
    }
  }

  cleanConnectionBean(): void {
    this.connectionBean = {} as CommonResultBean;
  }

  isASCP(ftpType: string): boolean {
    return this.isNonEmpty(ftpType) && ftpType.startsWith('ASCP');
  }

  isKeysAuthentication(ftpType: string): boolean {
    return this.isNonEmpty(ftpType) && (ftpType === 'ASCP_OWN_KEY' || ftpType === 'ASCP_PARTNER_KEY'
      || ftpType === 'SFTP_OWN_KEY' || ftpType === 'SFTP_PARTNER_KEY');
  }

  isOwnKeysAuthentication(ftpType: string): boolean {
    return this.isNonEmpty(ftpType) && (ftpType === 'ASCP_OWN_KEY' || ftpType === 'SFTP_OWN_KEY');
  }

  switchAutoRetry() {
    this.loaderService.showLoader();
    this.isAutoRetryOn = !this.isAutoRetryOn;
    this.globalConfigService.setIsAutoRetryOn(this.isAutoRetryOn)
      .subscribe({
        next: () => {
          this.loaderService.hideLoader();
          const successMessage = this.isAutoRetryOn
            ? this.dictionaryService.dictionary.channelList.messages.autoRetriesTurnedOn
            : this.dictionaryService.dictionary.channelList.messages.autoRetriesTurnedOff;
          this.messageService.success(successMessage);
        },
        error: (err) => {
          console.error(err);
          this.isAutoRetryOn = !this.isAutoRetryOn;
          this.loaderService.hideLoader();
          this.messageService.error(this.dictionaryService.dictionary.channelList.messages.autoRetriesSwitchError);
        }
      })
  }

  changeFtpSectionActive() {
    this.isFtpSectionActive=!this.isFtpSectionActive;
    console.log('changeFtpSectionActive');
  }

  changeHeaderActive() {
    console.log('changeHeaderActive');
  }
}
