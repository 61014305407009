import {Component, OnInit} from "@angular/core";
import {PlaylistSearchService} from "../schedule/search-playlist/search-playlist.service";
import {DictionaryService} from "../common/dictionary-service";
import {ChannelBean, ChannelsGroupBean} from "../model/generated/dtos";
import {CommonMessageService} from "../common/common-message-service";


@Component({
  selector: 'channel-group-list',
  templateUrl: './channel-group-list.component.html'
})
export class ChannelGroupListComponent implements OnInit {


  searchRequestBean: ChannelsGroupBean = {} as ChannelsGroupBean;
  formBean: ChannelsGroupBean = {} as ChannelsGroupBean;
  deleteChannelGroupBean: ChannelsGroupBean = {} as ChannelsGroupBean;
  currentChannelGroupName: string;
  fileExtOpen: boolean = false;
  searchFileExtOpen: boolean = false;
  foundChannels: ChannelBean[];
  currentChannel: ChannelBean;
  searchCurrentChannelGroup: ChannelsGroupBean;
  foundFilterChannelGroups: ChannelsGroupBean[];
  searchCurrentChannel: ChannelBean;
  foundFilterChannels: ChannelBean[];
  channelDisabled: boolean;
  channelGroupDisabled: boolean;
  suffixes: Array<string>;
  newSuffix: string;
  isFormSaved: boolean = false;
  isFormValid: boolean = false;


  inited = false;
  hasNextPage = false;
  page = 0;
  pageSize = 10;
  sortField = 'name';
  sortOrder = 1;

  channelGroupsList: ChannelsGroupBean[] = Array<ChannelsGroupBean>();

  //TABLE
  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.channelGroupList.tableHeaders.nameLabel,
      propertyName: 'name',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelGroupList.tableHeaders.workflowLabel,
      propertyName: 'workflow',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelGroupList.tableHeaders.suffixListLabel,
      propertyName: 'fileExtension',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.channelGroupList.tableHeaders.actionsLabel,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  constructor(private playlistSearchService: PlaylistSearchService,
              private messageService: CommonMessageService,
              public dictionaryService: DictionaryService) {

  }

  ngOnInit(): void {
    this.suffixes = [];
    this.loadChannelsGroups();
    this.channelDisabled = false;
    this.channelGroupDisabled = false;

  }

  //CRUD
  clearSearchFilter() {
    this.searchRequestBean = {} as ChannelsGroupBean;
    this.searchCurrentChannelGroup = null;
    this.searchCurrentChannel = null;
    this.autocompleteModelChange();
    this.searchChannelGroups();
  }

  saveChannelGroup(form: any) {
    this.isFormValid = true;

    if (this.formBean.name == null || this.formBean.name.trim().length == 0) {
      this.messageService.error(this.dictionaryService.dictionary.channelGroupList.messages.groupNameRequired);
      this.isFormValid = false;
      return false;
    }


    if (this.formBean.workflow == null || this.formBean.workflow.trim().length == 0) {
      this.messageService.error(this.dictionaryService.dictionary.channelGroupList.messages.wokflowRequired);
      this.isFormValid = false;
      return false;
    }


    this.prepareSuffixes();
    this.playlistSearchService.saveChannelGroup(this.formBean).subscribe(
      response => {
        this.searchChannelGroups();
        this.messageService.success(this.dictionaryService.dictionary.channelGroupList.messages.channelGroupSaved);
        this.closePopup();
      }, error => {
        console.error(error);
      }
    );

  }

  deleteChannelGroup() {
    this.playlistSearchService.deleteChannelGroup(this.deleteChannelGroupBean).subscribe(response => {
      this.closeConfirmPopup('delete-channel-group-dialog');
      this.searchChannelGroups();
      this.messageService.success(this.dictionaryService.dictionary.channelGroupList.messages.channelGroupDeleted);
    }, error => {
      this.messageService.error(this.dictionaryService.dictionary.channelGroupList.messages.channelGroupNotDeleted);
      console.error(error);
    })

  }

  searchChannelGroups() {
    this.inited = false;
    this.loadChannelsGroups();
  }

  buildFilter() {

    this.searchRequestBean = {} as ChannelsGroupBean;
    if (this.searchCurrentChannelGroup != null) {
      if (typeof this.searchCurrentChannelGroup === 'object') {
        this.searchRequestBean.name = this.searchCurrentChannelGroup.name;
      } else {
        if (this.searchCurrentChannelGroup != "") {
          this.searchRequestBean.name = this.searchCurrentChannelGroup;
        }
      }
    }

    if (this.searchCurrentChannel != null) {
      if (typeof this.searchCurrentChannel === 'object') {
        this.searchRequestBean.channelName = this.searchCurrentChannel.name;
      } else {
        if (this.searchCurrentChannel != "") {
          this.searchRequestBean.channelName = this.searchCurrentChannel;
        }
      }
    }
  }

  loadChannelsGroups() {

    if (!this.inited) {
      this.inited = true;
      this.channelGroupsList = new Array<ChannelsGroupBean>();
      this.page = 0;
    } else if (this.inited) {
      this.page += 1;
    }

    this.buildFilter();

    this.playlistSearchService.getSliceChannelGroups(this.searchRequestBean, this.page, this.pageSize, this.sortField, this.sortOrder).subscribe(
      response => {
        this.hasNextPage = response.hasNext;
        if (this.page == 0)
          this.channelGroupsList = new Array<ChannelsGroupBean>();
        response.content.forEach(thread => {
          this.channelGroupsList.push(thread);
        });
      }, error => {
        console.error(error);
      }
    );

  }

  chooseExtension(extension: string) {
    this.formBean.fileExtension = extension;
  }

  chooseSearchFileExt(extension: string) {
    this.searchRequestBean.fileExtension = extension;
  }

  //POPUPS

  openPopup(channelGroup?: ChannelsGroupBean): void {
    if (channelGroup) {
      this.formBean = Object.assign({}, channelGroup);
      this.currentChannelGroupName = this.formBean.name;
      this.extractSuffixes();
    } else {
      this.formBean = {} as ChannelsGroupBean;
      this.formBean.channels = [];
      this.suffixes = [];
      this.currentChannelGroupName = this.dictionaryService.dictionary.channelGroupList.dialog.header;
    }
    var outerHtmlElement: any = document.getElementById('channel-group-form-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  closePopup(): void {
    var outerHtmlElement: any = document.getElementById('channel-group-form-dialog');
    this.newSuffix = null;
    this.fixedClosePopup(outerHtmlElement);
  }

  fixedOpenPopup(dialogHtmlElement: any) {
    this.isFormSaved = false;
    let topOffset = window.pageYOffset + 100 + 'px';
    dialogHtmlElement.style.top = topOffset;
    dialogHtmlElement.setAttribute("open", "open");
    document.body.style.overflow = 'hidden';
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
    document.body.style.overflow = 'auto';
  }

  nextSlice(): void {
    this.loadChannelsGroups();
  }

  openConfirmDeletePopup(group: ChannelsGroupBean): void {
    if (group) {
      this.deleteChannelGroupBean = Object.assign({}, group);
    } else {
      this.deleteChannelGroupBean = {} as ChannelsGroupBean;
    }
    var outerHtmlElement: any = document.getElementById('delete-channel-group-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  closeConfirmPopup(elementId: string): void {
    elementId = elementId || 'delete-channel-group-dialog';
    var outerHtmlElement: any = document.getElementById(elementId);
    // outerHtmlElement.close();
    this.fixedClosePopup(outerHtmlElement);
  }

  //AUTOCOMPLETE
  searchChannels(event) {
    let ids = this.formBean.channels.map(channel => channel.id).join(',');
    if (ids == null) {
      ids = '';
    }
    this.playlistSearchService.getChannelsByName(event.query, ids).subscribe(data => {
      this.foundChannels = data;
    });
  }

  getSelectedChannel(event) {
    let channel = <ChannelBean>event;
    if (channel.id != -1) {
      this.addChannel(channel);
    } else {

    }
    this.currentChannel = null;
  }


  addChannel(channel: ChannelBean): void {
    this.formBean.channels.push(channel);
  }

  deleteInput(toDelete: ChannelBean) {
    this.formBean.channels.splice(this.formBean.channels.indexOf(toDelete), 1);
  }

  //SORT
  requestSorting(header: any): void {
    if (header.clickable) {
      this.sortField = header.propertyName;
      this.sortOrder = 0;
      if (header.asc) {
        this.sortOrder = 1;
      }
      this.searchChannelGroups();
    }
  }

  toggleArrow(index: number, header: any): void {
    if (this.headers[index].clickable) {
      if (this.headers[index].selected) {
        this.headers[index].asc = !this.headers[index].asc;
      } else {
        this.headers.forEach(header => header.selected = false);
        this.headers[index].selected = true;
      }
      this.requestSorting(header);
    }
  }

  searchFilterChannelGroups(event): void {
    this.playlistSearchService.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.foundFilterChannelGroups = data;
    });
  }



  searchFilterChannels(event) {
    this.playlistSearchService.getChannelsByName(event.query, '').subscribe(data => {
      this.foundFilterChannels = data;
    });
  }

  autocompleteModelChange(): void {
    this.channelGroupDisabled = this.getDisabledValue(this.searchCurrentChannel);
    this.channelDisabled = this.getDisabledValue(this.searchCurrentChannelGroup);
  }

  private getDisabledValue(object): boolean {
    if (object != null) {
      if (typeof object === 'object') {
        return object.name !== '';
      } else {
        return object !== '';
      }
    } else {
      return false;
    }
  }

  addSuffix(suff: string) {
    if (suff != null && suff != "") {
      if (!this.validateUniqueSuffix(suff)) {
        this.messageService.warning(this.dictionaryService.dictionary.channelGroupList.messages.suffixDuplicate)
      } else {
        if (this.suffixes != null) {
          this.suffixes.push(suff);
        }
        this.newSuffix = null;
      }

    } else {
      this.messageService.warning(this.dictionaryService.dictionary.channelGroupList.messages.suffixEmpty);
    }

  }

  deleteSuffix(suffix: string) {
    this.suffixes.splice(this.suffixes.indexOf(suffix), 1);
  }

  prepareSuffixes() {
    this.formBean.fileExtension = null;
    let extensions = this.suffixes.join(",");
    if (extensions != null && extensions != "") {
      this.formBean.fileExtension = extensions;
    }
  }

  validateUniqueSuffix(name: string): boolean {
    let existingNames = this.suffixes.map(ch => ch.toLowerCase());
    let repeated = existingNames.filter(ch => ch == name.toLowerCase());
    return repeated.length == 0;
  }

  extractSuffixes() {
    if (this.formBean.fileExtension != null) {
      this.suffixes = this.formBean.fileExtension.split(",");
    } else {
      this.suffixes = [];
    }
  }
}
