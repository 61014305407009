export default {
  app: {
    playlistsLink: 'Playlisty',
    statisticsLink: 'Statystyki',
    controlPanelLink: 'Panel kontrolny',
    usersLink: 'Użytkownicy',
    profilesLink: 'Profile',
    channelsGroupsLink: 'Grupy kanałowe',
    channelsLink: 'Kanały',
    notificationsLink: 'Powiadomienia',
    polish: 'Polski',
    english: 'Angielski',
  },
  calendar: {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
    dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec',
      'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    today: 'Dziś',
    clear: 'Wyczyść'
  },
  playlists: {
    header: 'Lista playlist',
    to: 'do',
    refresh: 'odświeżenia',
    search: {
      choseChannelPlaceholder: 'Wybierz grupę kanałów',
      selectAll: 'Wybierz wszystkie',
      previousDayTitle: 'Poprzedni dzień',
      nextDayTitle: 'Następny dzień',
      searchButtonLabel: 'Wyszukaj',
      clearButtonLabel: 'Wyczyść',
      materialsLabel: 'Ingest',
      materialsTitle: 'Nowe materiały do przygotowania na emisję',
      importButton: 'Importuj playlistę roboczą',
      changeButton: 'Zamień materiał',
      restoreButtonLabel: 'Przywróć wszystkie kanały',
      group: 'GRUPA',
    },
    grid: {
      channelHeader: 'Stacja',
      days: ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],
      materialsTotal: 'Liczba materiałów',
      materialsMissing: 'Brakujące',
      materialsVirtual: 'Wirtualne',
      materialsReady: 'Gotowe',
      materialsDraft: 'Robocza',
      materialsEmpty: 'BRAK',
      materialsPending: 'W trakcie'
    }
  },
  playlistDetails: {
    header: 'Szczegóły playlisty',
    transformationStatus: {
      new: 'W trakcie wysyłki',
      update: 'W trakcie wysyłki',
      lock: 'W trakcie wysyłki',
      technical_error: 'Błąd wysyłki',
      copying_error: 'Błąd wysyłki',
      done: 'Dostarczona',
      no_file: 'Brak pliku'
    },
    errorDetails: 'Szczegóły błędu',
    transformationStatusLabel: 'Status playlisty na FTP',
    errorDetailsNotAvailable: 'Brak szczegółów błędu.',
    errorRequest: 'Błąd podczas wykonywania zapytania',
    search: {
      channelNameLabel: 'Nazwa kanału',
      selectChannelPlaceholder: 'wybierz kanał',
      dateLabel: 'Data',
      blockBeginningLabel: 'Początek bloku',
      blockBeginningPlaceholder: 'OD',
      blockEndingPlaceholder: 'DO',
      materialStatusLabel: 'Status materiału',
      partnerIdLabel: 'ID Partnera',
      statuses: {
        ADMIN: {
          all: 'Wszystkie',
          sent_to_broadcaster: 'Dostarczony',
          file_not_exist: 'Brak materiału',
          prepare_emission: 'W przygotowaniu',
          new: 'W przygotowaniu',
          update: 'W przygotowaniu',
          retry: 'W przygotowaniu',
          retry_copying: 'W przygotowaniu',
          sending_to_broadcaster: 'W trakcie wysyłki',
          copying_error: 'Błąd wysyłki',
          processing_error: 'Błąd przetwarzania',
          processing_failed: 'Błąd przetwarzania',
          technical_error: 'Błąd przetwarzania',
          virtual: 'Wirtualny',
          material_null: 'Materiał niepodpięty',
          lock: 'W przygotowaniu',
          missing: 'Wybrano brakujące'
        },
        USER: {
          all: 'Wszystkie',
          sent_to_broadcaster: 'Dostarczony',
          file_not_exist: 'W przygotowaniu',
          prepare_emission: 'W przygotowaniu',
          processing: 'W przygotowaniu',
          new: 'W przygotowaniu',
          update: 'W przygotowaniu',
          retry: 'W przygotowaniu',
          retry_copying: 'W przygotowaniu',
          sending_to_broadcaster: 'W przygotowaniu',
          copying_error: 'W przygotowaniu',
          processing_error: 'W przygotowaniu',
          processing_failed: 'W przygotowaniu',
          technical_error: 'W przygotowaniu',
          virtual: 'Wirtualny',
          material_null: 'Materiał niepodpięty',
          lock: 'W przygotowaniu',
          missing: 'Wybrano brakujące'
        },
      },
      checkboxMissingLabel: 'Brakujące',
      blockTypeLabel: 'Typ bloku',
      blockTypes: {
        all: 'Wszystkie',
        OS: 'Ogłoszenie społeczne',
        R: 'Reklamowy',
        B: 'Sponsorski'
      },
      orderTypes: {
        cennik: 'Cennik',
        pakiet: 'Pakiet',
        sponsorski: 'Sponsorski'
      },
      filterButtonLabel: 'Filtruj',
      clearButtonLabel: 'Wyczyść filtry',
      refreshButtonLabel: 'Odśwież',
      resetTransformationButtonLabel: 'Ponów',
    },
    table: {
      expandAllLabel: 'Rozwiń wszystko',
      retractAllLabel: 'Zwiń wszystko',
      compareVersionsLabel: 'Porównaj wersje',
      versionLabel: 'Wersja',
      versionType: {
        'temporary': 'Wersja robocza',
        'closed': 'Wersja produkcyjna'
      },
      generatedDateLabel: 'Wygenerowana',
      downloadPlaylistButton: 'Pobierz playlisty',
      positionHeader: 'Pozycja',
      blockBeginningHeader: 'Początek bloku',
      blockSpotNameHeader: 'Nazwa bloku/spotu',
      partnerIdHeader: 'ID Partnera',
      dlHeader: 'DL (mm:ss:kl)',
      fileTypeHeader: 'Format',
      blockOrderTypeHeader: 'Typ bloku/zlecenia',
      beerHeader: 'Piwo',
      materialStatusHeader: 'Status materiału',
      actionsHeader: 'Akcje',
      noResults: 'Nie znaleziono wyników',
    },
    row: {
      expandLabel: 'Rozwiń',
      retractLabel: 'Zwiń',
    },
    messages: {
      refreshData: 'Przyjęto zlecenie odświeżenia danych',
      retryCopyingData: 'Przyjęto zlecenie ponowienia kopiowania danych'
    }
  },
  playlistComparison: {
    header: 'Porównanie playlist',
    table: {
      version: 'Wersja',
      blockCount: 'Liczba bloków',
      spotCount: 'Liczba spotów',
      generated: 'Wygenerowana',
      position: 'Pozycja',
      blockBeginning: 'Początek bloku',
      blockSpotName: 'Nazwa bloku/spotu',
      partnerIdHeader: 'ID Partnera',
      dl: 'DL (min:ss:kl)',
      blockOrderType: 'Typ bloku/zlecenia',
      beer: 'piwo',
    },
    search: {
      channelName: 'Nazwa kanału',
      selectChannel: 'wybierz kanał',
      emissionDate: 'Data emisji',
      ver1: 'Wersja 1',
      ver2: 'Wersja 2',
      selectVersion: 'Wybierz wersje',
      compareButton: 'Porównaj',
      downloadCompared: 'Pobierz Excel'
    },
    row: {
      differenceLabel: 'Różnica spotów',
      addedLabel: 'Dodany',
      deletedLabel: 'Usunięty',
      modifiedLabel: 'Zmodyfikowany'
    }
  },
  channelGroupList: {
    header: 'Grupy kanałowe',
    dialog: {
      header: 'Nowa grupa kanałowa',
      nameInputLabel: 'Nazwa',
      sufixListLabel: 'LISTA SUFIXÓW',
      sufixListDesc: 'Lista sufiksów dotyczy plików powstających w trakcie przetwarzania materiału. Aby wskazać pliki, które należy przesłać na FTP użytkownik powinien zdefiniować listę sufiksów. Pliki których nazwy, oprócz kodu materiału, zawierają jeden z sufiksów zdefiniowanych na liście poniżej zostaną przekopiowane do nadawcy.',
      sufixListExample: ' Przykładowy suffix: .mpg',
      channelsHeader: 'KANAŁY',
      channelInputPlaceholder: 'WYBIERZ KANAŁ',
      vantageHeader: 'KONFIGUROWANIE PARAMETRÓW VANTAGE',
      groupNameWarning: 'Nazwa grupy jest obowiązkowa!',
      save: 'Zapisz'
    },
    confirmDialog: {
      header: 'Potwierdzenie',
      question: 'Czy na pewno chcesz usunąć grupę kanałową',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak'
    },
    form: {
      nameInputLabel: 'Nazwa',
      nameInputPlaceholder: 'WYBIERZ GRUPĘ KANAŁOWĄ',
      channelInputLabel: 'Kanał',
      channelInputPlaceholder: 'WYBIERZ KANAŁ',
      filterButton: 'Filtruj',
      clearFiltersButton: 'Wyczyść filtry',
      newChannelGroupButton: 'Nowa grupa kanałowa'
    },
    results: {
      editButton: 'Edytuj',
      deleteButton: 'Usuń',
      noResults: 'Nie znaleziono wyników',
      showMore: 'Pokaż więcej'
    },
    tableHeaders: {
      nameLabel: 'Nazwa',
      workflowLabel: 'Workflow',
      workflowContextLabel: 'Workflow Context',
      suffixListLabel: 'Lista sufiksów',
      actionsLabel: 'Akcje'
    },
    messages: {
      groupNameRequired: 'Pole nazwa jest obowiązkowe!',
      wokflowRequired: 'Pole workflow jest obowiązkowe',
      channelGroupSaved: 'Grupa kanałowa została zapisana',
      channelGroupDeleted: 'Grupa kanałowa została pomyślnie usunięta',
      channelGroupNotDeleted: 'Nie można usunąć grupy kanałowej z nieusuniętymi kanałami',
      suffixDuplicate: 'Podany sufix już znajduję się na liście!',
      suffixEmpty: 'Nie można dodać pustego sufixa do listy sufixów!'
    }
  },
  channelList: {
    header: 'Kanały',
    dialog: {
      header: 'Nowy kanał',
      channelGroupInputLabel: 'Grupa kanałowa',
      channelGroupInputPlaceholder: 'Wybierz grupę kanałową',
      nameInputLabel: 'Nazwa',
      idInputLabel: 'Identyfikator',
      channelLogoHeader: 'LOGO KANAŁU',
      addLogoLabel: 'Dodaj logo',
      chooseFile: 'Wybierz',
      orLabel: 'lub',
      dropFileLabel: 'przeciągnij tu plik',
      chosenFileLabel: 'Wybrano plik',
      fileNameLabel: 'Nazwa pliku',
      ftpConfigurationHeader: 'KONFIGURACJA POŁĄCZENIA',
      fileProtocolLabel: 'Protokół pliku',
      fileProtocolNone: 'Brak',
      fileProtocolPlaceholder: 'Wybierz protokół pliku',
      ftpHost: 'Nazwa hosta',
      ftpPort: 'Numer portu',
      ftpPortError: 'Nieprawidłowa wartość w polu! Liczba z zakresu 0-65535.',
      ftpUser: 'Nazwa użytkownika',
      diffGenOmit: 'Wyłączenie generowania raportu różnic na ftp',
      processOverwrite: 'Nadpisywanie plików na ftp',
      disabled: 'Kanał zablokowany',
      autoRetry: 'Ponawiaj automatycznie import materiału przy błędach',
      ftpPassword: 'Hasło',
      ftpPasswordChange: 'Zmiana hasła',
      ftpPasswordState: 'STAN',
      ftpOptions: 'Opcje FTP',
      ftpOptionsDescription: {
        FtpSslAllowOff: 'Ta opcja wyłącza SSL dla połączenia FTP. SSL jest protokołem bezpieczeństwa, więc wyłączenie go oznacza, że połączenie nie będzie zabezpieczone.',
        FtpSslProtectDataTrue: 'Pomimo wyłączenia SSL, ta opcja włącza ochronę danych przez SSL. W przypadku wyłączonego SSL może to być niepotrzebne.',
        SslCheckHostnameFalse: 'Wyłącza sprawdzanie poprawności nazwy hosta SSL. Może to być przydatne w przypadku, gdy nazwa hosta nie jest skonfigurowana zgodnie z wymaganiami certyfikatu SSL.',
        SslVerifyCertificateNo: 'Wyłącza weryfikację certyfikatu SSL. Oznacza to, że klient nie będzie sprawdzał, czy certyfikat SSL serwera jest prawidłowy, co może zwiększyć ryzyko ataku typu Man-in-the-Middle.',
        SftpAutoConfirmYes: 'Automatycznie potwierdza operacje w protokole SFTP, bez potrzeby ręcznego zatwierdzania.',
        SftpConnectProgramSshRsa: 'Ustawia algorytm ssh-rsa jako preferowany do połączeń SFTP. Bezpieczny i powszechnie używany.',
        SftpConnectProgramSshDss: 'Ustawia algorytm ssh-dss (DSA) do połączeń SFTP. Przestarzały i wyłączony w nowoczesnych konfiguracjach. Używaj tylko z bardzo starymi systemami.',
      },
      ftpPasswordStateChange: 'Hasło zostanie zmienione!',
      ftpPasswordStateNotChange: 'Hasło nie zostanie zmienione',
      oldFtpPassword: 'Stare hasło',
      newFtpPassword: 'Nowe hasło',
      limit: 'Maksymalna ilość połączeń',
      ftpUrl: 'Katalog docelowy FTP',
      ftpDestination: 'Destynacja materiału (Wyjście Vantage)',
      ftpConfigurationError: 'Konfiguracja jest niedokończona! Wyczyść albo wypełnij poprawnie wszystkie pola!',
      channelGroupError: 'Pola grupa kanałowa oraz nazwa są obowiązkowe!',
      channelGroupOnlyError: 'Pole grupa kanałowa jest obowiązkowe!',
      channelNameOnlyError: 'Pole nazwa jest obowiązkowe!',
      saveButton: 'Zapisz',
      connectButton: 'Sprawdź',
      ftpGetProcessUrlError: 'Wystąpił błąd w trakcie pobierania URL modułu procesowego',
      ftpCheckFtpError: 'Wystąpił błąd w trakcie sprawdzania połączenia ftp',
      downloadPublicKey: 'Pobierz klucz publiczny',
      userPassUsage: ' – użytkownik + hasło',
      ascpOwnKeyUsage: 'ASCP – klucz własny',
      ascpPartnerKeyUsage: 'ASCP – klucz partnera',
      sftpOwnKeyUsage: 'SFTP – klucz własny',
      sftpPartnerKeyUsage: 'SFTP – klucz partnera'
    },
    confirmDialog: {
      header: 'Potwierdzenie',
      question: 'Czy na pewno chcesz usunąć kanał',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak'
    },
    form: {
      nameInputLabel: 'Nazwa',
      nameInputPlaceholder: 'WYBIERZ KANAŁ',
      channelGroupInputLabel: 'Grupa kanałowa',
      channelGroupInputPlaceholder: 'WYBIERZ GRUPĘ KANAŁOWĄ',
      filterButton: 'Filtruj',
      clearFilterButton: 'Wyczyść filtry',
      newChannelButton: 'Nowy kanał',
      autoRetry: 'Automatyczne ponowienia',
      turnOnAutoRetry: 'Włącz automatyczne ponowienia',
      turnOffAutoRetry: 'Wyłącz automatyczne ponowienia'
    },
    results: {
      editButton: 'Edytuj',
      deleteButton: 'Usuń',
      noResults: 'Nie znaleziono wyników',
      showMore: 'Pokaż więcej'
    },
    tableHeaders: {
      nameLabel: 'Nazwa',
      channelGroupLabel: 'Grupa kanałowa',
      typeLabel: 'Typ',
      ftpHostNameLabel: 'Nazwa hosta FTP',
      ftpPortLabel: 'Numer portu',
      ftpUserLabel: 'Użytkownik FTP',
      ftpUrlLabel: 'Katalog docelowy FTP',
      ftpDestinationLabel: 'Destynacja materiału',
      actionsLabel: 'Akcje'
    },
    messages: {
      channelSaved: 'Kanał został zapisany',
      logoAddingError: 'Błąd podczas dodawania loga do kanału',
      channelDelete: 'Kanał został pomyślnie usunięty!',
      logosSaved: 'Loga pomyślnie zostały przypisane do kanałów',
      oldPassWrong: 'Stare hasło ftp nie jest prawidłowe!',
      bothPassRequired: 'Przy zmianie hasła ftp wymagane jest wypełnienie pól starego oraz nowego hasła ftp!',
      autoRetriesTurnedOn: 'Automatyczne ponowienia zostały włączone',
      autoRetriesTurnedOff: 'Automatyczne ponowienia zostały wyłączone',
      autoRetriesSwitchError: 'Nastąpił błąd podczas próby przełączenia automatycznych ponowień',
    },
    ftpMessages: {
      correctConnection: 'Połączenie nawiązane poprawnie.',
      correctConnectionWithBadUrl: 'Połączenie zakończone błędem: niepoprawny adres url.',
      incorrectHost: 'Połączenie zakończone błędem: niepoprawna nazwa hosta.',
      incorrectPort: 'Połączenie zakończone błędem: niepoprawny numer portu.',
      incorrectHostOrPort: 'Połączenie zakończone błędem: serwis ftp nie istnieje.',
      incorrectUsernameOrPassword: 'Połączenie zakończone błędem: niepoprawna nazwa użytkownika lub hasło.',
      timeout: 'Połączenie zakończone błędem (timeout). Podany adres nie jest osiągalny.',
      unknownProblem: 'Wystąpił problem podczas próby połączenia.',
      processModuleRestProblem: 'Nie można sprawdzić połączenia. Wystąpił błąd w trakcie komunikacji z modułem obsługi ftp.'
    }
  },
  notificationsConfiguration: {
    header: 'Konfiguracja powiadomień',
    confirmDialog: {
      header: 'Potwierdzenie',
      questionFirstPart: 'Czy na pewno chcesz usunąć godzinę ',
      questionSecondPart: 'z Twojej konfiguracji powiadomień?',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak'
    },
    dialog: {
      newHourHeader: 'Nowa godzina',
      newHourLabel: 'Godzina',
      newHourPlaceholder: 'Wybierz godzinę',
      saveButton: 'Zapisz'
    },
    standardSettings: {
      header: 'STANDARDOWE USTAWIENIA',
      subHeader: 'Standardowe ustawienia',
      firstOption: 'wysyłanie pojedynczego emaila dla wszystkich kanałów z listy, ',
      secondOption: 'wysyłanie zbiorczego maila codziennie o godzinie 10:00.',
      description: {
        firstPart: 'W sekcji',
        secondPart: 'Lista powiadomień dla kanałów',
        thirdPart: 'jest możliwość wyboru dla których kanałów będzie wysyłane pojedyncze powiadomienie via email.',
        fourthPart: 'W sekcji',
        fifthPart: 'Konfiguracja częstotliwości otrzymywania wiadomości e-mailowych zbiorowych',
        sixthPart: ' istnieje możliwość zdefiniowania indywidualnych godzin wysyłania zbiorczych powiadomień o nowej playliście.',
        seventhPart: ' dotyczy zdefiniowania indywidualnych godzin wysyłania raportów o błędnych statusach materiałów.',
      }
    },
    list: {
      header: 'Lista powiadomień dla kanałów via email',
      noResults: 'Nie znaleziono wyników',
      frequencyConfigurationHeader: 'Konfiguracja częstotliwości otrzymywania wiadomości e-mailowych zbiorowych (tryb dzienny)',
      diffReportHeader: 'Konfiguracja generowania raportów rozbieżności',
      checkAll: 'Zaznacz wszystkie',
      uncheckAll: 'Odznacz wszystkie',
      addButton: 'Dodaj',
      deleteButton: 'Usuń',
    },
    tableHeaders: {
      channelNameLabel: 'Nazwa kanału',
      sendLabel: 'Wysyłaj',
      hourLabel: 'Godzina',
      actionLabel: 'Akcja'
    },
    diffReportsConfigtableHeaders: {
      profileLabel: 'Profil',
      sendReportsLabel: 'Wysyłaj raporty'
    },
    messages: {
      changesSaved: 'Pomyślnie zapisano zmiany w zbiorczej konfiguracji powiadomień!',
      channelConfigurationSavedFirstPart: 'Konfiguracja dla kanału ',
      channelConfigurationSavedSecondPart: ' została zapisana pomyślnie',
      timeNotChosenError: 'Aby kontynuować musisz wybrać godzinę',
      timeDuplicateError: 'Wybrana godzina jest już zapisana w konfiguracji powiadomień zbiorczych'
    }
  },
  detailsPopup: {
    header: 'Szczegóły pliku',
    previewTab: 'Podgląd',
    infoTab: 'Metryczka',
    downloadButton: 'Pobierz',
    spotTitle: 'Tytuł spotu',
    spotLength: 'Długość spotu',
    musicTitle: 'Tytuł muzyki',
    spotType: 'Typ spotu',
    copyrightDateBegin: 'Data rozpoczęcia praw autorskich',
    copyrightValidTo: 'Data ważności praw autorskich',
    composer: 'Kompozytor',
    lyricsAuthor: 'Autor słów',
    screenplay: 'Scenariusz',
    polishVersionAuthor: 'Autor wersji polskiej',
    producer: 'Producent',
    director: 'Reżyser',
    cinematographer: 'Operator obrazu',
    format: 'Format'
  },
  statusPopup: {
    header: 'Szczegóły statusu'
  },
  profileList: {
    header: 'Profile',
    dialog: {
      header: 'Nowy profil',
      nameInputLabel: 'Nazwa',
      tempPlaylistDaysLabel: 'Możliwość pobrania wersji roboczej (liczba dni)',
      tempPlaylistDaysError: 'Nieprawidłowa wartość w polu',
      channelsHeader: 'KANAŁY',
      channelInputPlaceholder: 'WYBIERZ KANAŁ',
      diffReportGenerationLabel: 'Generowanie raportu rozbieżności',
      diffReportGenerationOption: 'Zezwól',
      sendReportViaLabel: 'Sposób wysyłania plików playlisty i raportu rozbieżności',
      sendReportViaOptions: {
        viaPlaylistFtp: 'Przez FTP playlisty',
        viaEmail: 'Przez EMAIL',
        viaMaterialFtp: 'Przez FTP materiału'
      },
      viaFtp: 'Przez FTP',
      viaEmail: 'Przez EMAIL',
      viaMaterialFtp: 'Przez FTP materiału',
      ftpParametersHeader: 'PARAMETRY POŁĄCZENIA',
      fileProtocolLabel: 'Protokół pliku',
      fileProtocolPlaceholder: 'Wybierz protokół pliku',
      fileProtocolNone: 'Brak',
      ftpHost: 'Nazwa hosta',
      ftpPort: 'Numer portu',
      ftpPortError: 'Nieprawidłowa wartość w polu!',
      ftpUser: 'Nazwa użytkownika',
      ftpPassword: 'Hasło',
      ftpPasswordChange: 'Zmiana hasła',
      oldFtpPassword: 'Stare hasło',
      newFtpPassword: 'Nowe hasło',
      ftpPasswordState: 'STAN',
      ftpPasswordStateChange: 'Hasło zostanie zmienione!',
      ftpPasswordStateNotChange: 'Hasło nie zostanie zmienione',
      ftpUrl: 'Katalog docelowy (Serwer partnera)',
      ftpDestination: 'Destynacja materiału (Wyjście Vantage)',
      saveButton: 'Zapisz',
    },
    confirmDialog: {
      header: 'Potwierdzenie',
      question: 'Czy na pewno chcesz usunąć profil',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak',
    },
    form: {
      header: 'Profil',
      channelInputLabel: 'Kanał',
      channelInputPlaceholder: 'WYBIERZ KANAŁ',
      profileInputPlaceholder: 'NAZWA',
      filterButton: 'Filtruj',
      clearFilterButton: 'Wyczyść filtry',
      newProfileButton: 'Nowy profil'
    },
    table: {
      editButton: 'Edytuj',
      deleteButton: 'Usuń',
      noResults: 'Nie znaleziono wyników',
      showMore: 'Pokaż więcej'
    },
    tableLabels: {
      profileName: 'Nazwa \u000Aprofilu',
      byFtp: 'Przez \u000AFTP',
      byEmail: 'Przez \u000AE\u2011MAIL',
      byMaterialFTP: 'Przez \u000AFTP \u000Amateriału',
      diffGeneration: 'Dostęp do \u000Araportu \u000Arozbieżności',
      ftpHost: 'Nazwa \u000Ahosta \u000AFTP',
      ftpPort: 'Numer \u000Aportu',
      ftpProtocol: 'Protokół \u000Apliku',
      ftpUrl: 'Katalog \u000Adocelowy \u000AFTP',
      ftpUsername: 'Nazwa \u000Aużytkownika \u000AFTP',
      actions: 'Akcje'
    },
    messages: {
      profileSaved: 'Profil został zapisany',
      profileDeleted: 'Profil został usunięty',
      profileNameRequired: 'Nazwa profilu jest wymagana!',
      daysNumberRequired: 'Pole możliwość pobrania wersji roboczej jest wymagane!',
      daysNumberValueRange: 'Możliwość pobrania wersji roboczej dostępna tylko dla przedziału 0-62 dni!',
      uploadTypeRequired: 'Określenie sposobu wysyłki plików playlisty jest wymagane!',
      ftpProtocolRequired: 'Protokół pliku jest wymagany!',
      ftpHostRequired: 'Nazwa hosta FTP jest wymagana!',
      ftpPortRequired: 'Numer portu jest wymagany!',
      ftpUsernameRequired: 'Nazwa użytkownika FTP jest wymagana!',
      ftpPasswordRequired: 'Hasło FTP jest wymagane!',
      ftpUrlRequired: 'Kagalog docelowy FTP jest wymagany!',
      oldPassWrong: 'Stare hasło ftp playlisty nie jest prawidłowe!',
      bothPassRequired: 'Przy zmianie hasła ftp playlisty wymagane jest wypełnienie pól starego oraz nowego hasła ftp playlisty!',
      inputsRequired: 'Aby dodać nowy profil należy poprawnie uzupełnić wymagane pola!',
      inputsRequiredEdit: 'Aby zapisać zmiany należy poprawnie uzupełnić wymagane pola!'
    }
  },
  importPlaylist: {
    importDraftPlaylistHeader: 'Importuj playlistę roboczą',
    // POLA
    draftPlaylistDateFromLabel: 'Import od',
    draftPlaylistDateToLabel: 'Import do',
    draftPlaylistImportDateLabel: 'Data importu',
    draftPlaylistDatePlaceholder: 'Wybierz datę',
    importDraftPlaylistChannelHeader: 'KANAŁ',
    channelsInputPlaceholder: 'Wybierz kanał',
    importDraftPlaylistChannelGroupHeader: 'GRUPA KANAŁOWA',
    channelGroupsInputPlaceholder: 'Wybierz grupę kanałową',
    importDraftPlaylistProfilesHeader: 'PROFIL',
    profileChannelsImport: 'Importuj wszystkie kanały użytkownika',
    // WALIDACJE
    importDateMissing: 'Brak daty importu!',
    importChannelOrGroupMissing: 'Wymagane jest wybranie kanału lub grupy kanałowej',
    importChannelOrProfileChannel: 'Wymagane jest wybranie kanału lub zaznaczenie kanałów dla profilu',
    importDateOrderError: 'Data końca importu musi być późniejsza od daty początku',
    importDateLimitError: 'Błędny termin importu. Możliwy zakres to: ',
    // BŁĘDY
    errors: {
      requestError: 'Błąd podczas wykonywania żądania',
      noPlayListError: 'Brak playlisty dla wybranych parametrów',
      savePlaylistError: 'Błąd podczas zapisu playlisty na bazie danych',
      tooManyRequestsError: 'Zbyt wiele żądań. Spróbuj ponownie później',
      communicationError: 'Błąd komunikacji z BNG',
      closedPlaylistExist: 'W wybranym dniu istnieje wersja produkcyjna - brak możliwości pobrania wersji roboczej.',
      zeroChannelsProfileError: 'Profil użytkownika nie posiada żadnego kanału',
      zeroChannelError: 'Nie znaleziono kanału',
      zeroChannelGroupError: 'Nie znaleziono aktywnych kanałów dla podanej nazwy grupy kanałowej',
    },

    // KOMUNIKATY KONCA
    importStart: 'Przyjęto do realizacji',
    importFullSuccess: 'Pomyślnie zakończono import.',
    importPartialSuccess: 'Zakończono import. Wystąpiły błędy.',
    // OPIS STANU
    importPossibleDates: 'MOŻLIWY ZAKRES IMPORTU',
    importPlaylistDone: 'WYKONANE IMPORTY PLAYLIST',
    importInProgress: 'IMPORT W TRAKCIE',
    importSuccessfully: 'IMPORT ZAKOŃCZONY. UDANE IMPORTY PLAYLIST',
    importErrors: 'BŁĘDY IMPORTU',
    importErrorList: {
      channel: 'KANAŁ',
      playlistDate: 'DATA PLAYLISTY',
      description: 'OPIS BŁĘDU',
      zeroErrors: 'BRAK BŁĘDÓW IMPORTU',
      beforeImport: 'WYPEŁNIJ FORMULARZ I ROZPOCZNIJ IMPORT'
    },
    importButton: 'Importuj',
    errorOccured: 'Wystąpił błąd'
  },
  changeMaterialPopup: {
    header: 'Zamień materiał',
    possibleDates: 'Możliwy zakres zmiany',
    oldMaterialHeader: 'Stary materiał',
    newMaterialHeader: 'Nowy materiał',
    oldMaterialInputPlaceholder: 'Podaj kod starego materiału',
    newMaterialInputPlaceholder: 'Podaj kod nowego materiału',
    wasTitle: 'Było',
    nowTitle: 'Zamieniamy na',
    applyChangesButton: 'Zamień',
    // WALIDACJE
    dateMissing: 'Brak daty zmiany materiału!',
    channelOrGroupMissing: 'Wymagane jest wybranie kanału lub grupy kanałowej',
    oldMaterialMissing: 'Pole stary materiał nie może być puste',
    newMaterialMissing: 'Pole nowy materiał nie może być puste',
    dateOrderError: 'Data końca zmiany musi być późniejsza od daty początku',
    dateLimitError: 'Błędny termin zmiany materiału. Możliwy zakres to: ',
    operationSuccess: 'Podmiana materiałów zakończona sukcesem'
  },
  unauthorisedComponent: {
    header: 'Brak dostępu',
    text: 'Skontaktuj się z administratorem w celu nadania dostępu.'
  },
  authorisationWarning: {
    header: 'Brak dostępu',
    text: 'Nie masz dostępu do wybranej strony.'
  },
  userList: {
    tableLabels: {
      emailLabel: 'E-mail',
      createDateLabel: 'Data utworzenia',
      firstNameLabel: 'Imię',
      lastNameLabel: 'Nazwisko',
      loginLabel: 'Login',
      phoneNumberLabel: 'Telefon kontaktowy',
      roleLabel: 'Rola',
      profileLabel: 'Profil',
      statusLabel: 'Status',
      actionsLabel: 'Akcje'
    },
    header: 'Konfiguracja użytkowników',
    dialog: {
      header: 'Nowy użytkownik',
      roleInputLabel: 'Rola',
      roleInputPlaceholder: 'Wybierz rolę',
      roles: {
        roleAdmin: 'Administrator',
        roleManager: 'Manager',
        roleOperator: 'Operator',
        roleUser: 'Użytkownik'
      },
      profilesHeader: 'PROFIL',
      profilesPlaceholder: 'WYBIERZ PROFIL',
      emailInputLabel: 'E-mail',
      nameInputLabel: 'Imię',
      surnameInputLabel: 'Nazwisko',
      phoneInputLabel: 'Telefon kontaktowy',
      phoneInputError: 'Nieprawidłowy format danych w polu telefon kontaktowy!',
      saveButton: 'Zapisz'
    },
    confirmDeleteDialog: {
      header: 'Potwierdzenie',
      question: 'Czy na pewno chcesz usunąć użytkownika',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak',
    },
    confirmBlockDialog: {
      header: 'Potwierdzenie',
      questionFirstPart: 'Czy na pewno chcesz',
      questionSecondPartBlock: 'zablokować',
      questionSecondPartUnblock: 'odblokować',
      questionThirdPart: 'użytkownika',
      cancelButton: 'Anuluj',
      confirmButton: 'Tak'
    },
    form: {
      emailInputLabel: 'E-mail',
      loginInputLabel: 'Login',
      dateInputLabel: 'Data utworzenia',
      dateFrom: 'od:',
      dateTo: 'do:',
      nameInputLabel: 'Imię',
      surnameInputLabel: 'Nazwisko',
      roleInputLabel: 'Rola',
      allRoles: 'Wszystkie',
      profileInputLabel: 'Profil',
      profileAnyPlaceholder: 'Dowolny',
      filterButton: 'Filtruj',
      clearFilterButton: 'Wyczyść filtry',
      newUserButton: 'Nowy użytkownik'
    },
    table: {
      active: 'Aktywny',
      blocked: 'Zablokowany',
      editButton: 'Edytuj',
      blockButton: 'Zablokuj',
      unblockButton: 'Odblokuj',
      deleteButton: 'Usuń',
      showMore: 'Pokaż więcej',
      noResults: 'Nie znaleziono wyników'
    },
    messages: {
      filterDateRangeError: 'Błędny filtr dla daty utworzenia! Data początkowa musi być wcześniejsza od daty końcowej!',
      filterDateTodayError: 'Błędny filtr dla daty utworzenia! Data początkowa musi być wcześniej niż data bieżąca!',
      roleRequired: 'Tworzony użytkownik musi mieć określoną rolę!',
      emailRequired: 'Pole e-mail jest obowiązkowe!',
      emailInvalid: 'Niepoprawny format adresu e-mail!',
      nameRequired: 'Pole imię jest obowiązkowe!',
      lastNameRequired: 'Pole nazwisko jest obowiązkowe!',
      phoneNumberInvalid: 'W numerze telefonu dozwolone wyłącznie cyfry!',
      managerProfileError: 'Manager musi posiadać profil!',
      userProfileError: 'Użytkownik musi posiadać profil!',
      userSaved: 'Użytkownik został zapisany',
      userDeleted: 'Użytkownik został usunięty',
      userBlocked: 'Użytkownik został zablokowany',
      userUnblocked: 'Użytkownik został odblokowany',
      inputsRequired: 'Aby dodać nowego użytkownika należy poprawnie uzupełnić wymagane pola!'
    }
  },
  userAgreement: {
    header: 'Akceptacja regulaminu',
    agreement1: {
      firstPart: 'Oświadczam, że zapoznałem się z treścią niniejszego',
      link: 'regulaminu',
      secondPart: ', akceptuję go w całości bez żadnych zastrzeżeń i zobowiązuję się do jego przestrzegania.*'
    },
    agreement2: 'Wyrażam zgodę na przetwarzanie moich danych osobowych przez TVN Media sp. z o.o. z siedzibą w Warszawie do celów związanych ze świadczeniem usług dostępnych w Serwisie.*',
    agreement3: 'Wyrażam zgodę na przetwarzanie moich danych osobowych przez TVN S.A. z siedzibą w Warszawie do celów związanych ze świadczeniem usług dostępnych w Serwisie.*',
    agreement4: 'Zgadzam się na korzystanie z usług świadczonych drogą elektroniczną.*',
    buttonLabel: 'Zapisz',
    messages: {
      agreementsAccepted: 'Zgody zostały zapisane',
      agreementAcceptanceRequired: 'Akceptacja wszystkich zgód jest wymagana.'
    }
  },
  errors: {
    requestError: 'Błąd podczas wykonywania żądania',
    noPlayListError: 'Brak playlisty dla wybranych parametrów',
    channelGroupExist: 'Grupa kanałowa o podanej nazwie już istnieje!',
    userMissing: 'Brak użytkownika w systemie',
    userNotLoggedIn: 'Użytkownik nie jest zalogowany',
    authenticationRequired: 'Akcja wymaga zalogowanego użytkownika',
    userEmailExist: 'Użytkownik o podanym emailu już istnieje',
    badEmail: 'Błędny email',
    emailExist: 'Podany email już istnieje',
    unableToAddUser: 'Brak możliwości dodania użytkownika',
    unableToDeleteProfile: 'Nie można usunąć profilu, jeżeli jest on powiązany z użytkownikiem!',
    noSuchOrganization: 'Wystąpił niespodziewany błąd! Nie znaleziono organizacji o ID = $ !',
    noSuchCompany: 'Wystąpił niespodziewany błąd! Nie znaleziono jednostki o ID = $ !',
    profileNotFound: 'Nie znaleziono profilu o ID: $',
    channelNameDuplicate: 'Kanał o tej nazwie już istnieje',
    channelIdDuplicate: 'Kanał o tym identyfikatorze już istnieje',
    channelIdNull: 'Identyfikator nie może być pusty!',
    channelIdBadValue: 'Identyfikator musi być liczbą całkowitą dodatnią!',
    channelNameNull: 'Nazwa kanału nie może być pusta!',
    channelGroupNull: 'Grupa kanałowa nie może być pusta!',
    channelLimitBadValue: 'Limit połączeń musi być liczbą całkowitą dodatnią!',
    channelGroupNotFound: 'Nie znaleziono grupy kanałowej o ID: $',
    channelGroupNameDuplicate: 'Grupa kanałowa o nazwie: $ już istnieje',
    channelNotFound: 'Nie znaleziono kanału o ID: $',
    unableToDeleteChannelGroup: 'Nie można usunąć grupy kanałowej! Grupa kanałowa $ ma przypisane kanały',
    profileNameDuplicate: 'Profil o nazwie \'$\' już istnieje!',
    materialMissing: 'Brak materiału',
    noEmissionError: 'Brak informacji o szczegółach błędu dla wybranego elementu',
    uploadedFileTooLargeError: 'Wybrany plik jest zbyt duży!',
    emptyPlaylistId: 'Przesłana wartość playlistId nie powinna być nullem',
    playlistNotFound: 'Nie znaleziono playlisty o ID: $',
    versionNotValid: 'Pracujesz na nieaktualnej wersji playlisty. Odśwież stronę, aby załadować aktualną wersję.',
    statusAlreadyChanged: 'Status wybranego elementu playlisty różni się od jego obecnego statusu w systemie. Odśwież stronę, aby załadować aktualną wersję.',
    noDivergencesReport: 'Nie zostały zamknięte wszystkie playlisty dla kanałów w Twoim profilu',
    noPermissionsSaveUserWithProfile: 'Brak uprawnień do utworzenia konta o tym profilu',
    noPermissionsDeleteUserWithProfile: 'Brak uprawnień do usunięcia konta',
    noPermissionsDisableUserWithProfile: 'Brak uprawnień do zablokowania konta użytkownika',
    noPermissionsEnableUserWithProfile: 'Brak uprawnień do odblokowania konta użytkownika',
    noPermissionRole: 'Brak uprawnień do edycji użytkownika z wybraną rolą',
    noPermissionEdit: 'Brak uprawnień do edycji użytkownika',
    noRole: 'Brak przypisanej roli do użytkownika',
    noProfileError: 'Profil jest obowiązkowy dla menadżera oraz użytkownika',
    noSuchUser: 'Nie znaleziono użytkownika o ID = $',
    noNotifyConfigForUserAndChannel: 'Nie znaleziono konfiguracji powiadomień dla użytkownika i kanału ID = $',
    userValidationEmailMaxLength: 'E-mail jest za długi. Maksymalna liczba znaków: 255.',
    userValidationNameMaxLength: 'Imię jest za długie. Maksymalna liczba znaków: 50.',
    userValidationLastNameMaxLength: 'Nazwisko jest za długie. Maksymalna liczba znaków: 100.',
    userValidationNumberMaxLength: 'Telefon kontaktowy jest za długi. Maksymalna liczba znaków: 30.',
    materialNotFound: 'Nie znaleziono materiału o podanym kodzie',
    moreThanOneMaterial: 'Istnieje wiele materiałów o podanym kodzie',
    channelOrChannelGroupNotFound: 'Nie znaleziono żadnego kanału',
    playlistElementsNotFound: 'Nie znaleziono elementów playlisty spełniających podane filtry',
    profileNameInvalid: 'Nazwa profilu zawiera niedozwolone znaki',
    profileHostInvalid: 'Nawa Hosta zawiera niedozwolone znaki',
    profileUrlInvalid: 'Adres url zawiera niedozwolone znaki',
    profileUsernameInvalid: 'Nawa użytkownika zawiera niedozwolone znaki',
    profilePasswordInvalid: 'Hasło zawiera niedozwolone znaki',
    profileTypeInvalid: 'Typ profilu zawiera niedozwolone znaki',
    channelNameInvalid: 'Nawa kanału zawiera niedozwolone znaki',
    channelHostInvalid: 'Nawa Hosta zawiera niedozwolone znaki',
    channelUsernameInvalid: 'Nazwa użytkownika zawiera niedozwolone znaki',
    channelPasswordInvalid: 'Hasło zawiera niedozwolone znaki',
    channelPathInvalid: 'Nazwa pliku z logo zawiera niepoprawne znaki',
    channelGroupNameInvalid: 'Nzzwa grupy kanałowej zawiera niedozwolone znaki',
    userFirstNameInvalid: 'Imie zawiera niedozwolone znaki',
    userLastNameInvalid: 'Nazwisko zawiera niedozwolone znaki',
    userLoginInvalid: 'Login zawiera niedozwolone znaki',
    userPhoneNumberInvalid: 'Numer telefonu zawiera niedozwolone znaki',
    userEmailInvalid: 'Adres email zawiera niedozwolone znaki',
    userRoleInvalid: 'Rola zawiera niedozwolone znaki',
    userProfileNameInvalid: 'Nazwa profilu zawiera niedozwolone znaki',
    userNameInvalid: 'Nawa użytkownika zawiera niedozwolone znaki',
    materialCodeInvalid: 'Kod materiału zawiera niedozwolone znaki',
    materialNameInvalid: 'Nazwa materiału zawiera niedozwolone znaki',
    playlistNameInvalid: 'Nazwa playlisty zawiera niedozwolone znaki',
    fileNameInvalid: 'Nazwa pliku zawiera niedozwolone znaki',
    filterInvalid: 'Filtr zawiera niedozwolone znaki',
    importMaterialError: 'Błąd podczas importowania danych materiału z zewnętrznego źródła',
    limitOfConnectionExceeded: 'Przekroczono limit połączeń z serwerem klienta. Trwa przetwarzanie, spróbuj ponownie za chwilę',
  },
  dictionaries: {
    roles: {
      ADMIN: 'Administrator',
      MANAGER: 'Manager',
      OPERATOR: 'Operator',
      USER: 'Użytkownik',
      ALL: 'Wszystkie'
    }
  },
  statistics: {
    header: 'Statystyki elementów playlisty',
    playlistElementsHeader: 'LISTA ELEMENTÓW PLAYLISTY',
    missingMaterialsHeader: 'MATERIAŁY KTÓRYCH NIE UDAŁO SIĘ POBRAĆ',
    retryProcessingResponse: 'Przetwarzanie zostało uruchomione dla $ par [Kanał - Materiał]',
    retryCopyingResponse: 'Ponowienie kopiowania zostało uruchomione dla $ par [Kanał - Materiał]',
    retryProcessingZeroError: 'Brak zaznaczonych pozycji',
    retryProcessingZeroErrorResp: 'Przetwarzanie anulowane. Zaznaczone elementy posiadają nieaktualny status. Dane zostają odświeżone. ',
    retryCopyingZeroErrorResp: 'Ponowienie kopiowania anulowane. Zaznaczone elementy posiadają nieaktualny status. Dane zostają odświeżone. ',
    retryProcessingError: 'Wystąpił błąd przy próbie ponowienia przetwarzania materiałów',
    getElementsError: 'Podczas pobierania listy elementów wystąpił błąd',
    showMore: 'Pokaż więcej',
    noResults: 'Nie znaleziono wyników',
    expand: 'rozwiń',
    shrink: 'zwiń',
    refreshStatistics: 'Odśwież statystyki',
    statuses: {
      sent_to_broadcaster: 'Dostarczony',
      file_not_exist: 'Brak materiału',
      prepare_emission: 'W przygotowaniu',
      new: 'W przygotowaniu',
      update: 'W przygotowaniu',
      retry: 'W przygotowaniu',
      retry_copying: 'W przygotowaniu',
      sending_to_broadcaster: 'W trakcie wysyłki',
      copying_error: 'Błąd wysyłki',
      processing_error: 'Błąd przetwarzania',
      processing_failed: 'Błąd przetwarzania',
      technical_error: 'Błąd przetwarzania',
      virtual: 'Wirtualny',
      material_null: 'Materiał niepodpięty',
      missing: 'Wybrano brakujące'
    },
    playlistElements: {
      idTableHeader: 'ID\u00A0elementu /ID\u00A0playlisty',
      actionTableHeader: 'Akcja',
      code: 'Kod',
      channelGroup: 'Grupa kanałowa',
      chooseChannelGroup: 'Wybierz grupę kanałową',
      channel: 'Kanał',
      chooseChannel: 'Wybierz kanał',
      playlistDate: 'Data playlisty',
      fromDate: 'Data od',
      toDate: 'Data do',
      materialName: 'Nazwa materiału',
      elementId: 'ID elementu',
      materialStatus: 'Status materiału',
      materialStatusDetails: 'Szczegóły statusu materiału',
      statusDate: 'Data statusu',
      filter: 'Filtruj',
      cleanFilters: 'Wyczyść filtry',
      retry: 'Ponów',
      retryCopy: 'Ponów kopiowanie',
      checkAll: 'Zaznacz wszystkie',
      unCheckAll: 'Odznacz wszystkie',
      pageSize: 'Liczba pobieranych rekordów',
      filterMatchingCount: {
        1: 'Liczba',
        2: 'wyników'
      }
    },
    missingMaterials: {
      materialName: 'Nazwa materiału',
      materialNameHeader: 'Nazwa materiału [ID]',
      createDate: 'Data utworzenia',
      fromDate: 'Data od',
      toDate: 'Data do',
      retryCount: 'Ilość wznowień',
      filter: 'Filtruj',
      cleanFilters: 'Wyczyść filtry'
    }
  },
  controlPanel: {
    title: 'Panel kontrolny',
    searchBox: {
      channelsGroupInput: {
        label: 'Grupa kanałowa',
        placeholder: 'Wybierz grupę kanałową'
      },
      channelInput: {
        label: 'Kanał',
        placeholder: 'Wybierz kanał'
      },
      playlistDates: {
        label: 'Data playlisty',
        placeholderFrom: 'Data od',
        placeholderTo: 'Data do'
      },
      materialName: {
        label: 'Nazwa materiału',
      },
      fileName: {
        label: 'Nazwa pliku',
      },
      sendDate: {
        label: 'Data wysyłki',
        placeholder: 'Wybierz datę'
      },
      processingStatus: {
        label: 'Status przetwarzania',
        placeholder: 'Wybierz status'
      },
      showAll: {
        label: 'Pokaż wszystkie',
      },
      filter: {
        label: 'Filtruj'
      },
      clearFilter: {
        label: 'Wyczyść filtry'
      },
      selectStatus: {
        material: 'Materiał',
        playlist: 'Playlista',
        any: 'DOWOLNY STATUS'
      }
    },
    fileTable: {
      headers: {
        database: 'Baza danych',
        ftp: 'FTP',
        materialType: 'Typ pliku',
        materialName: 'Nazwa materiału',
        fileNameDb: 'Nazwa pliku',
        processingStatus: 'Status przetworzenia',
        retryCounter: 'Licznik ponowień',
        action: 'Akcja',
        fileNameFtp: 'Nazwa pliku',
        sendDateFtp: 'Data',
        fileSize: 'Wielkość',
        fileHost: 'Host',
        filePort: 'Port',
        fileUser: 'Użytkownik',
        fileDir: 'Katalog',
      },
      data: {
        playlistPrefix: 'Playlista'
      },
      info: {
        chooseFilter: 'Wybierz grupę kanałową lub kanał oraz liczbę dni',
        noResults: 'Brak wyników dla wybranych filtrów',
        noFileInfo: 'Brak informacji o pliku',
        virtual: 'Materiał wirtualny',
        fileNotFound: 'Plik nie znaleziony',
        notApplicable: 'Nie dotyczy'
      }
    },
    buttons: {
      checkAll: 'Zaznacz wszystkie',
      unCheckAll: 'Odznacz wszystkie',
      retry: 'Ponów',
      retryCopy: 'Kopiuj i nadpisz',
    },
    processingStatus: {
      any: 'DOWOLNY STATUS',
      materialStatus: {
        sent_to_broadcaster: 'Dostarczony',
        file_not_exist: 'Brak materiału',
        prepare_emission: 'W przygotowaniu',
        new: 'W przygotowaniu',
        update: 'W przygotowaniu',
        retry: 'W przygotowaniu',
        retry_copying: 'W przygotowaniu',
        lock: 'W przygotowaniu',
        sending_to_broadcaster: 'W trakcie wysyłki',
        copying_error: 'Błąd wysyłki',
        processing_error: 'Błąd przetwarzania',
        processing_failed: 'Błąd przetwarzania',
        technical_error: 'Błąd przetwarzania',
        virtual: 'Wirtualny'
      },
      materialSelect: {
        sent: 'Dostarczony',
        not_exist: 'Brak materiału',
        prepare: 'W przygotowaniu',
        sending: 'W trakcie wysyłki',
        copying_error: 'Błąd wysyłki',
        process_error: 'Błąd przetwarzania',
        virtual: 'Wirtualny'
      },
      playlistStatus: {
        done: 'Dostarczona',
        no_file: 'Brak pliku',
        new: 'W trakcie wysyłki',
        update: 'W trakcie wysyłki',
        lock: 'W trakcie wysyłki',
        technical_error: 'Błąd wysyłki',
        copying_error: 'Błąd wysyłki'
      },
      playlistSelect: {
        done: 'Dostarczona',
        no_file: 'Brak pliku',
        sending: 'W trakcie wysyłki',
        error: 'Błąd wysyłki'
      }
    },
    errors: {
      getData: 'Błąd pobierania danych historii',
      getChannels: 'Wystąpił błąd pobierania kanałów',
      channelsGroupOrChannelNull: 'Musisz wybrać grupę kanałową lub kanał!',
      datesNull: 'Daty playlisty są wymagane!',
      datesOrder: 'Data początku musi być przed datą końca!',
      datesRange: 'Błędny zakres dat! Dozwolony zakres: ',
      retryProcessing: 'Wystąpił błąd przy próbie ponowienia przetwarzania materiałów',
      retryProcessingZero: 'Brak zaznaczonych pozycji',
      retryProcessingZeroResponse: 'Przetwarzanie anulowane. Zaznaczone elementy posiadają nieaktualny status. Dane zostają odświeżone. ',
      retryProcessingResponse: 'Przetwarzanie zostało uruchomione dla $ par [Kanał - Materiał]',
      retryCopyingResponse: 'Ponowienie kopiowania zostało uruchomione dla $ par [Kanał - Materiał]',
      retryCopyingZeroResponse: 'Ponowienie kopiowania anulowane. Zaznaczone elementy posiadają nieaktualny status. Dane zostają odświeżone. ',
    },
    actions: {
      retry_ok: 'Ponowienie kopiowania uruchomione',
      retry_error: 'W trakcie próby ponowienia kopiowania wystąpił błąd!'
    },
    materialTypes: {
      EMISSION_FILE: 'Plik techniczny',
      MATERIAL: 'Materiał',
      PLAYLIST: 'Playlista'
    }
  },
  footer: {
    policy_private: 'Polityka Prywatności',
  },
  user_panel: {
    account_menu: {
      other_applications: 'Inne aplikacje',
      change_password: 'Zmień Hasło',
      sign_out: 'Wyloguj'
    }
  }
};
